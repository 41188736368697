import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import {
  Box,
  FormControlLabel,
  List,
  Radio,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import HelpTooltip from 'artkive/components/Tooltip/HelpTooltip'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

import processingIcon from 'images/ecom/processing.svg'

import OptionListItem from '../OptionListItem'
import UpgradesCard from '../UpgradesCard'

import ContentBox from './ContentBox'

const useProcessingStyles = makeStyles(({ spacing }) => ({
  radioRoot: {
    padding: 0,
    alignSelf: 'flex-start',
  },
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: spacing(1.5),
    lineHeight: 'unset',
  },
  secondaryText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))

const ProcessingItem = ({ item: p, onChange, dense }) => {
  const isMobile = useMobileBreakpoint()

  const parts = p.title.split('\n')
  const labelId = `checkbox-list-secondary-label-${p.name}`
  const classes = useProcessingStyles()

  return (
    <OptionListItem
      label={(
        <FormControlLabel
          control={
            <Radio
              onChange={() => onChange(p)}
              checked={p.default}
              color={'primary'}
              inputProps={{ 'aria-labelledby': labelId }}
              name={'processTime'}
              id={'processTime'}
              className={classes.radioRoot}
            />
          }
          label={(
            <Box
              display={'flex'}
              alignItems={isMobile ? 'flex-start' : 'baseline'}
              flexDirection={isMobile ? 'column' : 'row'}
              flexWrap={'wrap'}
            >
              <Box textAlign={'left'} fontWeight={'fontWeightBold'} width={120}>
                <Typography variant={'body2'}>
                  {p.name} &nbsp;
                </Typography>
              </Box>
              <Typography variant={'caption'} component={'div'} classes={{ root: classes.secondaryText }}>
                {parts.map((part, index) => (<Box px={.25} key={index}>{part}</Box>))}
              </Typography>
            </Box>
          )}
          classes={{
            root: classes.root,
            label: classes.label,
          }}
        />
      )}
      className={cl({ dense: dense || isMobile, highlightable: !isMobile && !dense })}
      active={p.default}
      price={p.price !== '0.0' ? '$' + p.price.replace('.0', '') : 'FREE'}
    />
  )
}

ProcessingItem.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dense: PropTypes.bool,
}

ProcessingItem.defaultProps = {
  dense: false,
}

const ProcessingTime = ({ processing, onChange, dense = false }) => {
  const content = (
    <List disablePadding>
      {processing.map((p) => (
        <ProcessingItem key={p.name} item={p} onChange={onChange} dense={dense} />
      ))}
    </List>
  )

  const tip = (
    <HelpTooltip size={'large'} title={'Processing Time'}>
      <Typography>
        This is the time frame from when we receive your art to when you
        receive your book or mosaic proof. This includes professional photography and editing of your art, as well
        as designing, creating, and shipping your keepsake.
      </Typography>
      <Typography style={{ marginTop: 12 }}>
        Timeline will extend if second payment or proof
        approval by customer are not completed within 24 hours. Please note these timelines are general
        estimates, and we’ll do our best to meet them.
      </Typography>
    </HelpTooltip>
  )

  if (dense) {
    return (
      <UpgradesCard
        icon={<Image src={processingIcon} style={{ width: 24 }} />}
        title={(
          <>
            Processing Time {tip}
          </>
        )}
      >
        {content}
      </UpgradesCard>
    )
  }

  return (
    <ContentBox>
      <Box display={'flex'} alignItems={'center'} mb={2.5}>
        <Typography variant={'h6'}>Processing Time</Typography>
        {tip}
      </Box>

      {content}
    </ContentBox>
  )
}

ProcessingTime.propTypes = {
  processing: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  dense: PropTypes.bool,
}

export default ProcessingTime
