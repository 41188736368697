import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#EDEBF4',
    color:'#7A68AE',
    fontWeight: 600,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    whiteSpace:'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(237, 235, 244, 0.7)',
    },
  },
}))

const AccentLink = ({ className, href, children, ...rest }) => {
  const classes = useStyles()
  return (
    <Button
      variant={'text'}
      href={href}
      classes={classes}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default AccentLink
