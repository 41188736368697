import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import EditIcon from '@material-ui/icons/Edit'
import { useLocation } from 'wouter'

import { useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import formatNumber from 'artkive/utils/formatNumber'
import theme from 'artkive/utils/theme'

import { buildOrderDetailsList } from './BoxOrderDetailsSummary'

const usePreviewStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  root: {
    paddingLeft: 12,
  },
}))

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    borderRadius: 8,
    [breakpoints.down('sm')]: {
      boxShadow: '0px 4px 10px rgba(16, 35, 113, 0.08)',
    },
  },
  header: {
    backgroundColor: '#FDE4A5',
  },
  content: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FEF6E1;',
  },
  textRoot: {
    marginLeft: spacing(2),
  },
  productTitle: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: palette.secondary.dark,
    fontWeight: 'bold',
  },
  productSubtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  productSubtitlePrice: {
    color: palette.common.black,
  },
  listItemRoot: {
    padding: 0,
  },
  listRoot: {
    padding: '0 12px',
    fontWeight: 500,
    fontSize: '14px',
  },
  refundText: {
    color: theme.colors.green.main,
  },
  subtotal: {
    color: palette.common.black,
    fontWeight: 500,
  },
  container: {
    width: '100%',
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: palette.common.black,
    paddingRight: spacing(6),
  },
  itemRoot: {
    paddingLeft: 12,
  },
  image: {
    width: 54,
    height: 40,
    objectFit: 'cover',
    borderRadius: 2,
  },
  qtyBadge: {
    backgroundColor: theme.colors.purple.dark,
    color: theme.colors.white.main,
  },
  discountText: {
    color: palette.primary.main,
  },
  arrow: {
    padding: 4,
  },
  price: {
    right: 0,
  },
}))

const MobileOrderDetails = ({ product }) => {
  const classes = useStyles()
  const previewClasses = usePreviewStyles()
  const [isShown, setIsShown] = useState(false)
  const [, navigate] = useLocation()

  const {
    price,
    qty,
    processing,
    addOns,
  } = useVariableState(product.type, product.uuid)

  const activeProcessing = processing && processing.find((p) => !!p.default)
  const isPromoDiscount = !!price?.promo_discount_details?.code
  const subtotalPrice = price.base_price * qty

  const items = useMemo(() => (
    buildOrderDetailsList(addOns, activeProcessing, price)
  ), [addOns, activeProcessing, price.shipping_protection_price, isPromoDiscount])

  return (
    <Paper elevation={0} className={classes.root}>
      <ListItem
        component={'div'}
        ContainerComponent={'div'}
        classes={previewClasses}
        dense
      >
        <ListItemAvatar>
          <Badge badgeContent={qty} classes={{ badge: classes.qtyBadge }}>
            <img src={product.image} className={classes.image} />
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={`${product.title}`}
          secondary={(
            <>
              Total:&nbsp;
              <span className={classes.productSubtitlePrice}>
                ${price && formatNumber(price.total)}
              </span>
            </>
          )}
          classes={{
            root: classes.textRoot,
            primary: classes.productTitle,
            secondary: classes.productSubtitle,
          }}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => setIsShown(!isShown)} className={classes.arrow}>
            {isShown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={isShown} timeout={'auto'} unmountOnExit>
        <List className={classes.listRoot}>
          <ListItem disableGutters className={classes.listItemRoot}>
            <ListItemText primary={product.title} classes={{ primary: classes.label }} />
            <ListItemSecondaryAction className={classes.price}>
              ${formatNumber(subtotalPrice)}
            </ListItemSecondaryAction>
          </ListItem>

          {isPromoDiscount && (
            <ListItem disableGutters className={classes.listItemRoot}>
              <ListItemText
                primary={'Discount'}
                classes={{ primary: cl(classes.label, classes.discountText) }}
              />
              <ListItemSecondaryAction className={cl(classes.discountText, classes.price)}>
                -${formatNumber(Math.abs(price.promo_discount))}
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {items.map(({ name, price }) => (
            <ListItem key={name} disableGutters className={classes.listItemRoot}>
              <ListItemText primary={name} classes={{ primary: classes.label }} />
              <ListItemSecondaryAction className={classes.price}>
                {+price ? `+$${formatNumber(price)}` : 'FREE'}
              </ListItemSecondaryAction>
            </ListItem>
          ))}

          {!!price?.tax && (
            <ListItem disableGutters className={classes.listItemRoot}>
              <ListItemText primary={'Tax'} classes={{ primary: classes.label }} />
              <ListItemSecondaryAction className={classes.price}>
                ${formatNumber(price.tax)}
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
        <Box display={'flex'} justifyContent={'center'} my={1}>
          <Button
            onClick={() => navigate(`/${BOX_CHECKOUT_STEPS.ORDER_DETAILS}`)}
            color={'primary'}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Box>
      </Collapse>
    </Paper>
  )
}

MobileOrderDetails.propTypes = {
  product: PropTypes.object.isRequired,
}

export default MobileOrderDetails
