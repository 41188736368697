import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Controller, useFormContext } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns'
import { Grid, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'

import DatePicker from 'common/components/Form/DatePicker'
import PhoneNumberField from 'artkive/components/Forms/PhoneNumberField'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import validations from 'artkive/utils/validations'

const RecipientAddressFragment = ({ errors, withPhoneNumber = false }) => {
  const isMobile = useMobileBreakpoint()
  const { unregister } = useFormContext()

  useEffect(() => {
    return () => {
      unregister([
        'toEmail',
        'deliveryDate',
      ])
    }
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={12} md={6}>
          <Controller
            name={'toEmail'}
            defaultValue={''}
            rules={validations.email}
            shouldUnregister
            render={({ field }) => (
              <TextField
                error={!!errors.toEmail}
                helperText={errors?.toEmail?.message}
                margin={isMobile ? 'dense' : 'normal'}
                fullWidth={true}
                label={'Recipient Email'}
                type={'text'}
                variant={'outlined'}
                autoComplete={'none'}
                {...field}
              />
            )}
          />
        </Grid>

        {withPhoneNumber && (
          <Grid item xs={12} md={6}>
            <Controller
              name={'toPhone'}
              defaultValue={''}
              render={({ field }) => (
                <PhoneNumberField
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  margin={isMobile ? 'dense' : 'normal'}
                  fullWidth={true}
                  label={'Recipient Phone Number (Optional)'}
                  variant={'outlined'}
                  autoComplete={'none'}
                  {...field}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Controller
            name={'deliveryDate'}
            rules={{ required: 'Delivery Date is required' }}
            render={({ field }) => (
              <DatePicker
                error={!!errors.deliveryDate}
                inputProps={{ autoComplete: 'off' }}
                helperText={errors?.deliveryDate?.message}
                margin={isMobile ? 'dense' : 'normal'}
                label={'Delivery Date'}
                {...field}
                variant={'outlined'}
                fullWidth
                disablePast
              />
            )}
            shouldUnregister
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

RecipientAddressFragment.propTypes = {
  errors: PropTypes.object,
  withPhoneNumber: PropTypes.bool,
}

export default RecipientAddressFragment
