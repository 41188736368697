import React, { useContext } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@material-ui/core'

import ClearTextField from 'artkive/components/Forms/ClearTextField/ClearTextField'
import { TrackerContext } from 'artkive/components/Tracker'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import validations from 'artkive/utils/validations'

const PersonalNameFragment = () => {
  const isMobile = useMobileBreakpoint()
  const { formState: { errors } } = useFormContext()
  const { fire } = useContext(TrackerContext)

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      <Grid item xs={12} md={6}>
        <Controller
          name={'firstName'}
          defaultValue={''}
          rules={validations.firstName}
          render={({ field }) => (
            <ClearTextField
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'First Name'}
              type={'text'}
              variant={'outlined'}
              {...field}
              onBlur={(e) => {
                field.onBlur(e)
                fire('blur')
              }}
              autoComplete={'given-name'}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name={'lastName'}
          defaultValue={''}
          rules={validations.lastName}
          render={({ field }) => (
            <ClearTextField
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'Last Name'}
              type={'text'}
              variant={'outlined'}
              {...field}
              onBlur={(e) => {
                field.onBlur(e)
                fire('blur')
              }}
              autoComplete={'family-name'}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default PersonalNameFragment
