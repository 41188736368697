import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Controller, FormProvider } from 'react-hook-form'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'

import format from 'common/utils/format'
import { RecipientAddressFragment } from 'artkive/components/Checkout/Sections'
import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PaymentInformationFragment from 'artkive/components/Checkout/Sections/PaymentInformationFragment'
import PersonalNameFragment from 'artkive/components/Checkout/Sections/PersonalNameFragment'
import RecipientNameFragment from 'artkive/components/Checkout/Sections/RecipientNameFragment'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import formatNumber from 'artkive/utils/formatNumber'

import RecordingMemoriesSection from '../RecordingMemoriesSection'

import useInformationForm from './useInformationForm'

const defaultFormOptions = { recorder: 'me' }

const ShippingAndPayment = ({ onSubmit, product, isLoading }) => {
  const { payment, price, freeOrder } = useVariableState(product.type, product.uuid)
  const isMobile = useMobileBreakpoint()
  const {
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    ...methods
  } = useInformationForm(product, onSubmit, defaultFormOptions)
  const { setValue, formState: { errors, isValid }, watch, reset } = methods
  const isConfirmDisabled = !watch('tos') || !(isValid || freeOrder)
  const currentRecorderValue = methods.watch('recorder')

  useEffect(() => {
    const paymentValues = {
      cardExp: '',
      cardCvc: '',
      cardNumber: '',
      name: '',
      zipCode: '',
      ...payment,
      tos: payment.tos || false,
      recorder: 'me',
      toEmail: '',
      deliveryDate: format(new Date(), 'date'),
      toPhone: '',
    }
    reset(paymentValues, { keepValues: true })
  }, [reset, setValue])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        {recaptchaComponent}

        <Box mb={2}>
          <Typography variant={'h6'}>Contact Information</Typography>
        </Box>

        <PersonalNameFragment />

        <ContactInformationFragment errors={errors} productType={product.type} formType={'information'} />

        <Box mb={isMobile ? 1 : 2} mt={3.5}>
          <Typography variant={'h6'}>Who will be recording memories?</Typography>
        </Box>

        <RecordingMemoriesSection name={'recorder'} />

        {currentRecorderValue === 'else' && (
          <React.Fragment key={'recipient_information'}>
            <Box mt={3.5} mb={2}>
              <Typography variant={'h6'}>Recipient Contact Information</Typography>
            </Box>

            <RecipientNameFragment />

            <RecipientAddressFragment errors={errors} withPhoneNumber />

            <Typography variant={'h6'} mt={4}>Personal Note</Typography>

            <Grid item xs={12}>
              <Controller
                name={'note'}
                render={({ field }) => (
                  <TextField
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                    multiline
                    fullWidth={true}
                    minRows={4}
                    margin={isMobile ? 'dense' : 'normal'}
                    label={'Your Personal Note'}
                    inputProps={{ maxLength: 5000 }}
                    {...field}
                    variant={'outlined'}
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        )}

        <Box mb={isMobile ? 1 : 2} mt={3.5}>
          <Typography variant={'h6'}>Payment Information</Typography>
        </Box>

        <PaymentInformationFragment errors={errors} disabled={freeOrder} />

        <Box mt={6} display={'flex'} justifyContent={'space-around'}>
          <Button
            color={'primary'}
            disabled={isLoading || isConfirmDisabled || !recaptchaLoaded}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          >
            Place Order {!!price.total && (
              <>
                • ${formatNumber(price.total)}
              </>
            )}
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

ShippingAndPayment.propTypes = {
  product: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}

export default ShippingAndPayment
