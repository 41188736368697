import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'
import { Box, Button, Typography } from '@material-ui/core'

import useFormWithRecaptcha from 'artkive/hooks/useFormWithRecaptcha'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import formatNumber from 'artkive/utils/formatNumber'

// style
import PaymentInformationFragment from '../Checkout/Sections/PaymentInformationFragment'

const emptyObj = {}

const Payment = ({
  defaultValues = emptyObj,
  isLoading,
  onSubmit,
  product,
  enableRecaptcha = false,
}) => {
  const isMobile = useMobileBreakpoint()
  const { setStep } = useVariableActions(product.type, product.uuid)
  const { payment, price } = useVariableState(product.type, product.uuid)

  const {
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    ...methods
  } = useFormWithRecaptcha({
    enabled: enableRecaptcha,
    defaultValues: {
      cardExp: '',
      cardCvc: '',
      cardNumber: '',
      name: '',
      billingZipCode: '',
      ...defaultValues,
      ...payment,
      tos: payment.tos ?? false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    onSubmit,
  })

  const { formState: { errors, isValid }, watch } = methods

  useEffect(() => {
    setStep({ stepName: 'payment', payload: { isDirty: true } })
  }, [])

  const isConfirmDisabled = !watch('tos') || !isValid

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit} autoComplete={'on'}>
        {recaptchaComponent}

        <Box mb={isMobile ? 0 : 2}>
          <Typography variant={'h6'}>Payment Information</Typography>
        </Box>

        <PaymentInformationFragment errors={errors} />

        <Box mt={6}>
          <Button
            color={'primary'}
            disabled={isLoading || isConfirmDisabled || !recaptchaLoaded}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          >
            Place Order • ${formatNumber(price.total)}
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

Payment.propTypes = {
  defaultValues: PropTypes.object,
  error: PropTypes.string,
  withReviewStep: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  product: PropTypes.object,
  setError: PropTypes.func,
  enableRecaptcha: PropTypes.bool,
}

export default Payment
