import React from 'react'

import cl from 'classnames'
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Carousel from 'artkive/components/Carousel'
import Image from 'artkive/components/Image'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import HowItWorks, { HowItWorksButton } from 'artkive/components/PageSections/HowItWorks'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import Social from 'artkive/components/Social'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { defaultHowItWorks } from 'artkive/stores/ecom/constants/data'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Bubbles from 'images/ecom/brand/bubbles.svg'
import sprite from 'images/ecom/brand/sprite.svg'
import bubbles from 'images/ecom/get-started/bubbles.png'
import lines from 'images/ecom/get-started/lines_3.svg'
import HeroCover from 'images/ecom/home/HeroCover.png'
import ArtBooks from 'images/ecom/home/Home-ArtBooks.png'
import Mosaics from 'images/ecom/home/Home-Mosaics.jpg'

import { homeBookProductBullets } from '../Books/Books.constants'
import DigitizedImagesAccessSection from '../Books/DigitizedImagesAccessSection'
import GetStartedWithBoxSection from '../Books/GetStartedWithBoxSection'
import { homeMosaicsProductBullets } from '../Mosaics/Mosaics.content'

import AsFeaturedOnSection from './AsFeaturedOnSection'
import GetStartedFooter from './GetStartedFooter'
import { SLIDES } from './Home.constants'
import SeeUsInMotionSection from './SeeUsInMotionSection'
import TrustIndexReviews from './TrustIndexReviews'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    backgroundColor: theme.colors.white.dark,
  },
  heroWrapper: {
    [breakpoints.up('md')]: {
      background: 'linear-gradient(90deg, #DBF1FF 40%, #E3F4FF 40%);',
    },
  },
  heroImage: {
    maxWidth: '100%',
    [breakpoints.up('md')]: {
      maxHeight: '500px',
    },
  },
  howItWorksImage: {
    maxWidth: 120,
    height: 'auto',
  },
  howItWorksDescription: {
    lineHeight: 1.6,
    fontWeight: 500,
  },
  productsGrid: {
    position: 'relative',
    zIndex: 1,
  },
  buttonGutter: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: "' '",
      background: `url(${lines}) no-repeat`,
      backgroundSize: '135px 124px',
      transform: 'translate(0, 0)',
      width: 135,
      height: 124,
      right: 32,
      bottom: 48,
      [breakpoints.down('sm')]: {
        right: -4,
        bottom: 16,
        transform: 'translate(0, 0)',
        width: 96,
        height: 88,
        backgroundSize: '96px 88px',
      },
    },
  },
  bubblesGutter: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    '&::before': {
      zIndex: 0,
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      left: -30,
      bottom: -45,
    },
    '&::after': {
      zIndex: 0,
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      right: -20,
      top: -30,
    },
  },
}))

const useSpriteStyles = makeStyles(({ breakpoints }) => ({
  container: {
    position: 'absolute',
    zIndex: 2,
    top: -24,
    right: 4,

    [breakpoints.up('md')]: {
      top: -50,
      right: -40,
    },
  },
}))

const Sprite = () => {
  const classes = useSpriteStyles()

  return (
    <div className={classes.container}>
      <Image src={sprite} style={{ width: '75px', transform: 'scaleX(-1)' }} />
    </div>
  )
}

const Home = () => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {/* I CAN BE YOUR HERO BABAY */}
      <ContainedSection className={classes.heroWrapper} py={0} disableGutters>
        <Grid container>
          <Grid
            item
            sm={12}
            md={5}
            lg={6}
            className={cl(classes.heroWrapper, classes.bubblesGutter)}
          >
            <picture>
              <img src={HeroCover} className={classes.heroImage} />
            </picture>
          </Grid>

          <Grid item sm={12} md={7} lg={6} className={classes.buttonGutter}>
            <Box
              pt={isMobile ? 2 : 0}
              pb={isMobile ? 8 : 0}
              px={isMobile ? 0 : 4}
              textAlign={isMobile ? 'center' : 'left'}
              height={'100%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Box mb={2}>
                <Typography variant={'h1'} style={{ textTransform: 'capitalize' }}>
                  The #1 solution for preserving and celebrating kids&apos; art!
                </Typography>
              </Box>
              <Typography
                variant={isMobile ? 'body1' : 'subtitle1'}
                color={'textSecondary'}
              >
                We transform your piles of kid art into Art Books and Framed
                Mosaics.
              </Typography>

              <Box mt={isMobile ? 3 : 6}>
                <TrackButton
                  color={'primary'}
                  variant={'contained'}
                  href={routes.boxCheckout}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{ section: 'home_start_banner' }}
                  size={'large'}
                >
                  Get My Box
                </TrackButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ContainedSection>

      {/* I CAN BE YOUR HERO BABAY */}

      {/* START our products */}
      <Box
        bgcolor={theme.colors.white.main}
        py={isMobile ? 8 : theme.spacings.section}
      >
        <Container>
          <Box mb={isMobile ? 6 : 8} textAlign={'center'}>
            <Typography variant={'h2'}>Our Products</Typography>
          </Box>
          {/* accents */}
          <Grid container spacing={5} className={classes.productsGrid}>
            <Sprite />

            <Grid xs={12} md={6} item>
              <Box position={'relative'} zIndex={1} maxWidth={'100%'}>
                <Hidden smDown>
                  <Box
                    position={'absolute'}
                    bottom={'-45px'}
                    left={'-60px'}
                    zIndex={-1}
                  >
                    <Image
                      src={Bubbles}
                      style={{ width: '95px' }}
                    />
                  </Box>
                </Hidden>
                <Image alt={'Art Books'} src={ArtBooks} title={'ArtBooks'} />
              </Box>

              <Box px={isMobile ? 0 : 12} pt={4} pb={6}>
                <Typography variant={'h3'} align={'center'}>
                  Art Books
                </Typography>
                {homeBookProductBullets.map(({ text }, index) => (
                  <ProductDescriptionItem key={index}>
                    {text}
                  </ProductDescriptionItem>
                ))}
              </Box>

              <Box
                display={'flex'}
                justifyContent={'center'}
                textAlign={'center'}
              >
                <TrackButton
                  color={'primary'}
                  href={routes.books}
                  size={'large'}
                  variant={'outlined'}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{
                    section: 'get_books_banner',
                  }}
                >
                  Learn More
                </TrackButton>
              </Box>
              <Hidden mdUp>
                <Box py={4} />
              </Hidden>
            </Grid>

            <Grid xs={12} md={6} item>
              <Box position={'relative'} zIndex={1} maxWidth={'100%'}>
                <Hidden mdUp>
                  <Box
                    position={'absolute'}
                    bottom={'-35px'}
                    left={'-35px'}
                    zIndex={-1}
                  >
                    <Image
                      src={Bubbles}
                      style={{ width: '75px' }}
                    />
                  </Box>
                </Hidden>
                <Image
                  src={Mosaics}
                  alt={'Framed Mosaics'}
                  title={'Frames Mosaics'}
                />
              </Box>

              <Box px={isMobile ? 0 : 12} pt={4} pb={6}>
                <Typography variant={'h3'} align={'center'}>
                  Framed Mosaics
                </Typography>

                {homeMosaicsProductBullets.map(({ text }, index) => (
                  <ProductDescriptionItem key={index}>
                    {text}
                  </ProductDescriptionItem>
                ))}
              </Box>

              <Box display={'flex'} justifyContent={'center'}>
                <TrackButton
                  color={'primary'}
                  href={routes.mosaics}
                  size={'large'}
                  variant={'outlined'}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{
                    section: 'get_books_banner',
                  }}
                >
                  Learn More
                </TrackButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* END our products */}

      {/* START - how it works */}
      <HowItWorks
        steps={defaultHowItWorks}
        title={'How It Works'}
        variant={'image'}
      >
        <HowItWorksButton
          title={'Get My Box'}
          href={routes.boxCheckout}
          trackerEvent={GET_BOX_CLICK}
          trackerOptions={{ section: 'home_how_it_works' }}
        />
      </HowItWorks>

      {/* END - how it works */}
      <GetStartedWithBoxSection />

      <DigitizedImagesAccessSection bgcolor={isMobile ? undefined : theme.colors.white.main} />

      {/* START - Testimonials */}
      <ContainedSection
        disableGutters
        title={
          <Typography variant={'h2'} align={'center'}>
            400,000 families, 20 million pieces of art and counting
          </Typography>
        }
      >
        <TrustIndexReviews />

        <Box justifyContent={'center'} display={'flex'} mt={isMobile ? 6 : 8}>
          <TrackButton
            color={'primary'}
            variant={'contained'}
            href={routes.boxCheckout}
            trackerEvent={GET_BOX_CLICK}
            trackerOptions={{
              section: 'home_how_it_works',
            }}
            size={'large'}
          >
            Get My Box
          </TrackButton>
        </Box>
      </ContainedSection>
      {/* END - Testimonials */}

      {/* Carousel */}
      <Container disableGutters={isMobile}>
        <Box position={'relative'} my={10} mx={4}>
          {/* accents */}
          <Box position={'absolute'} top={isMobile ? '-50px' : '-50px'} left={isMobile ? '-50px' : '-50px'} zIndex={1}>
            <Image src={sprite} style={{ width: '50px' }} />
          </Box>
          <Carousel isMobile={isMobile} slides={SLIDES} />
        </Box>
      </Container>

      {/* START featured on */}
      <AsFeaturedOnSection />
      {/* END featured on */}

      {/* SeeUsInMotion */}
      <SeeUsInMotionSection />

      {/* Join The Fun  */}
      <Social />
      <GetStartedFooter />
    </div>
  )
}

export default withApp(Home)
