import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@material-ui/core'

import ClearTextField from 'artkive/components/Forms/ClearTextField/ClearTextField'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import validations from 'artkive/utils/validations'

const RecipientNameFragment = () => {
  const isMobile = useMobileBreakpoint()
  const { formState: { errors } } = useFormContext()

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      <Grid item xs={12} md={6}>
        <Controller
          name={'recipientFirstName'}
          rules={validations.firstName}
          render={({ field }) => (
            <ClearTextField
              error={!!errors.recipientFirstName}
              helperText={errors?.recipientFirstName?.message}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'Recipient First Name'}
              type={'text'}
              variant={'outlined'}
              autoComplete={'none'}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name={'recipientLastName'}
          render={({ field }) => (
            <ClearTextField
              error={!!errors.recipientLastName}
              helperText={errors?.recipientLastName?.message}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'Recipient Last Name'}
              type={'text'}
              variant={'outlined'}
              autoComplete={'none'}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default RecipientNameFragment
