import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'react-hook-form'
import cl from 'classnames'
import {
  Box,
  Button,
  Hidden,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'wouter'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import MobileHeader from 'artkive/components/Checkout/MobileHeader'
import { BackButton } from 'artkive/components/Header'
import MarkdownText from 'artkive/components/MarkdownText'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import ProductDescriptionBox, { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import HelpTooltip from 'artkive/components/Tooltip/HelpTooltip'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { fetchBoxPrice as fetchPrice } from 'artkive/stores/ecom/api/fetchPrice'
import validatePromoCode from 'artkive/stores/ecom/api/validatePromoCode'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import formatNumber from 'artkive/utils/formatNumber'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'
import theme from 'artkive/utils/theme'
import * as track from 'artkive/utils/tracker'

// styled components
import { ReturnArtTip, SubtitleTypography } from './GetStarted.style'
import ProductDescriptionSection from './ProductDescriptionSection'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '96px',
    paddingTop: 0,
    width: '100%',
  },
  singleSection: {
    justifyContent: 'center',
    paddingBottom: 0,
  },
  howItWorksImage: {
    maxWidth: 120,
    height: 'auto',
  },
  howItWorksDescription: {
    lineHeight: 1.6,
    fontWeight: 500,
  },
  circleIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#DC87B9',
    marginTop: 6,
  },
  backBtn: {
    zIndex: 1,
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
}))

const GetStarted = ({ productData, params, productLinks }) => {
  const classes = useStyles()
  const [price, setPrice] = useState({})

  const { fullName, information, promoCode } = useVariableState(BOX_PRODUCT_TYPE, productData.uuid)

  const data = useMemo(() => {
    const additionalText = get(productData, 'properties.checkout.additional_text', [])
    return {
      back_link: get(productData, 'properties.back_link', '') || '/',
      cover_url: get(productData, 'cover_image.url', ''),
      price: get(productData, 'sale_price', 0),
      header: get(productData, 'properties.checkout.header', []),
      subheader: get(productData, 'properties.checkout.subheader', ''),
      mobile_bullet_header: get(productData, 'properties.checkout.mobile_bullet_header', ''),
      return_artwork: get(productData, 'properties.options.addon_sections')
        .some(({ items }) => items.kind === ADDONS.RETURN_ART),
      bullets: get(productData, 'properties.checkout.bullets', []),
      additional_text: additionalText,
      how_it_works: get(productData, 'properties.checkout.how_it_works', null),
      additionalPromoStars: new Array(additionalText.length + 1).fill('*').join(''),
    }
  }, [productData])


  const validatePromo = useCallback(async () => {
    let code = params.promo || promoCode
    const addOns = []

    try {
      await validatePromoCode(code, productData.id, BOX_PRODUCT_TYPE, information.email)
        .then((promoValidate) => {
          if (promoValidate?.discount_method === DISCOUNT_METHODS.FREE_USB) {
            addOns.push({ kind: ADDONS.RETURN_USB })
          }
          if (promoValidate?.discount_method === DISCOUNT_METHODS.FREE_SHIPPING) {
            addOns.push({ kind: ADDONS.RETURN_ART })
          }
        }).catch(() => {
          code = ''
        })

      const currentPricePayload = {
        promo_code: code,
        add_ons: addOns,
        concierge_product_id: productData.id,
        concierge_processing_id: 1,
        quantity: 1,
        shipping_protection: false,
        email: information.email,
      }

      const priceResponse = await fetchPrice(currentPricePayload)

      setPrice(priceResponse)
    } catch (e) {
      console.error(e)
    }
  }, [params, productData, promoCode, information])

  // NOTE: this fetches price based on the promo code that was read from the URL or just was in the store
  useEffect(() => {
    validatePromo()
  }, [promoCode])

  useEffect(() => {

    const timer = setTimeout(() => {
      track.initiateCheckout({
        amount: price?.total,
        discount: price?.promo_discount,
        email: information.email,
        name: fullName ? fullName : undefined,
        product: 'box',
        promo: price?.promo_discount_details?.code,
      })
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  const isPromoDiscountAvailable = [DISCOUNT_METHODS.FLAT, DISCOUNT_METHODS.PERCENTAGE, DISCOUNT_METHODS.FLAT_PER_ITEM]
    .includes(price?.promo_discount_details?.discount_method)

  const circleIcon = <div className={classes.circleIcon} />

  const checkoutLink = typeof location !== 'undefined' ? `${productLinks.checkout}${location.search}` : productLinks.checkout

  const discountPrice = price.total
  const basePrice = price.base_price || data.price
  const isSingleSection = !data.how_it_works

  return (
    <Box className={cl(classes.root, { [classes.singleSection]: isSingleSection })}>
      <MobileHeader
        backButtonProps={{ href: data.back_link }}
        title={params.uuid ? 'Checkout' : 'Get My Box'}
      />
      <Hidden smDown>
        <BackButton href={data.back_link} className={classes.backBtn} />
      </Hidden>

      <ProductDescriptionSection
        image={(<img src={data.cover_url} />)}
        single={!data.how_it_works}
        productBg={theme.colors.bg.pink_dark}
        descriptionBg={theme.colors.secondary.light}
        height={isSingleSection ? '100vh' : 'auto'}
      >
        <ProductDescriptionBox
          single={!data.how_it_works}
          title={(
            <>
              {data.header[0]} <br /> {data.header[1]}
            </>
          )}
          price={(
            <>
              {isPromoDiscountAvailable && (
                <Typography
                  variant={'h5'}
                  component={'div'}
                  color={'secondary'}
                  style={{ textDecoration: 'line-through', marginRight: 12 }}
                >
                  ${basePrice}
                </Typography>
              )}
              <Typography variant={'h2'} component={'div'} color={'primary'} style={{ lineHeight: 1.4 }}>
                ${formatNumber(isPromoDiscountAvailable ? discountPrice : basePrice)}
              </Typography>
            </>
          )}
          additional={(
            <>
              {!!data.additional_text.length && data.additional_text.map(({ text, tip }, index) => (
                <Typography style={{ fontSize: '14px' }} component={'div'} key={index}>
                  {text}
                  {!!tip && (
                    <HelpTooltip size={'medium'} title={'Artkive Box Price'}>
                      {tip}
                    </HelpTooltip>
                  )}
                </Typography>
              ))}

              {!!price.promo_discount_details?.code && (
                <Typography style={{ fontSize: '14px' }} component={'div'}>
                  {data.additionalPromoStars}Promo
                  Code <strong>{price.promo_discount_details.code.toUpperCase()}</strong> applied
                </Typography>
              )}
            </>
          )}
          link={checkoutLink}
          subheader={(
            <>
              <Hidden smDown>
                <Box lineHeight={1.6} mb={4}>
                  <SubtitleTypography>
                    <MarkdownText source={data.subheader} />
                  </SubtitleTypography>
                </Box>
              </Hidden>

              {data.return_artwork ? (
                <Hidden mdUp>
                  <Box display={'flex'} mt={0.25}>
                    {circleIcon}
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'}>
                      <MarkdownText source={data.subheader} />
                    </Box>
                  </Box>
                  <Box display={'flex'} mt={0.25}>
                    {circleIcon}
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'}>
                      You can choose to have your art returned in the next step.
                    </Box>
                  </Box>
                </Hidden>
              ) : (
                <Hidden mdUp>
                  <Box display={'flex'} mt={0.25} justifyContent={'center'}>
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'} textAlign={'center'}>
                      <MarkdownText source={data.subheader} />
                    </Box>
                  </Box>
                </Hidden>
              )}
            </>
          )}
        >
          {data.mobile_bullet_header && (
            <Hidden mdUp>
              <Box mb={3}>
                <Typography variant={'h5'} align={'center'}>
                  {data.mobile_bullet_header}
                </Typography>
              </Box>
            </Hidden>
          )}

          {data.bullets.map(({ text }, index) => (
            <ProductDescriptionItem key={index}>
              {text}
            </ProductDescriptionItem>
          ))}

          {data.return_artwork && (
            <Hidden smDown>
              <ReturnArtTip elevation={0}>
                You can choose to have your art returned in the next step.
              </ReturnArtTip>
            </Hidden>
          )}
        </ProductDescriptionBox>
      </ProductDescriptionSection>

      {data.how_it_works && (
        <HowItWorks
          title={data.how_it_works.title}
          steps={data.how_it_works.items}
          variant={data.how_it_works.variant}
        >
          <Box justifyContent={'center'} display={'flex'} pt={10}>
            <Button
              color={'primary'}
              component={Link}
              href={checkoutLink}
              size={'large'}
              style={{ paddingLeft: 72, paddingRight: 72 }}
              variant={'contained'}
            >
              Continue
            </Button>
          </Box>
        </HowItWorks>
      )}

    </Box>
  )
}

GetStarted.propTypes = {
  productData: PropTypes.object,
  params: PropTypes.object,
  productLinks: PropTypes.object,
}

export default GetStarted
