import React from 'react'

import cl from 'classnames'
import { Box, Container } from '@material-ui/core'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

const ContainedSection = ({ children, title, subtitle, disableGutters, containerClassName, ...rest }) => {
  const isMobile = useMobileBreakpoint()

  const spacing = isMobile ? theme.spacings.sectionMobile : theme.spacings.section
  const hasTitleSection = !!title || !!subtitle

  return (
    <Box py={spacing} {...rest}>
      <Container disableGutters={disableGutters || !isMobile} className={cl(containerClassName)}>
        {hasTitleSection && (
          <Box mb={isMobile ? 6 : 8}>
            {title}
            {subtitle}
          </Box>
        )}

        {children}
      </Container>
    </Box>
  )
}

export default ContainedSection
