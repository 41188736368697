const createEventsSection = (section, events) => Object.fromEntries(
  Object.entries(events).map(([alias, event]) => [alias, `${section}_${event}`]),
)

export const GET_BOX_CLICK = 'ui:get_box_click'
export const SOCIAL_MEDIA_CLICK ='ui:social_media_click'
export const PAGES_SCROLL = 'ui:pages_scroll'
export const BACK_CLICK = 'ui:back_click'
export const CHECKOUT_USB_RETURN_CHANGE = 'ui:checkout_usb_return_change'
export const CHECKOUT_ART_RETURN_CHANGE = 'ui:checkout_art_return_change'

/**
 * Header and Footer sections
 * @typedef {Object} HEADER_FOOTER
 */
export const NAVIGATION = createEventsSection('ui:navigation', {
  LOGO_CLICK: 'logo_click',
  KEEPSAKES_CLICK: 'keepsakes_click',
  ART_BOOK_CLICK: 'art_book_click',
  MOSAIC_CLICK: 'mosaic_click',
  OUR_PROCESS_CLICK: 'our_process_click',
  CARDS_CLICK: 'cards_by_artkive_click',
  ABOUT_US_CLICK: 'about_us_click',
  PRICING_CLICK: 'pricing_click',
  GIFT_CARD_CLICK: 'gift_card_click',
  MEMBERSHIP_CLICK: 'membership_click',
  LOGIN_CLICK: 'login_click',
  OTHER_PRODUCTS_CLICK: 'other_products_click',
  FAQS_CLICK: 'faqs_click',
  CONTACT_US_CLICK: 'contact_us_click',
  DIGITAL_CLICK: 'digital_click',
  EMAIL_CLICK: 'email_click',
})

/**
 * Get $10 Off banner
 * @typedef {Object} DISCOUNT_BANNER
 */
export const DISCOUNT_BANNER = createEventsSection('ui:discount_banner', {
  EMAIL_FIELD_CLICK: 'email_field_click',
  APPLY_CLICK: 'apply_click',
  CTA_CLICK: 'cta_click',
  PRIVACY_POLICY_CLICK: 'privacy_policy_click',
  TERMS_AND_CONDITIONS_CLICK: 'terms_&_conditions_click',
  CLOSE_BANNER_CLICK: 'close_banner_click',
  CLOSE_MODAL_CLICK: 'close_modal_click',
})

/**
 * Get $20 Modal
 * @typedef {Object} REFERRAL_MODAL
 */
export const REFERRAL_MODAL = createEventsSection('ui:referral_modal', {
  CTA_CLICK: 'cta_click',
  COPY_CLICK: 'copy_click',
  SHARE_CLICK: 'share_click',
})

/**
 * Box Checkout
 * @typedef {Object} BOX_CHECKOUT
 */
export const BOX_CHECKOUT = createEventsSection('ui:box_checkout', {
  LANDING_NEXT_CLICK: 'landing_next_click',
  PROMO_CODE_APPLY: 'promo_code_apply',
  NEXT_CLICK: 'next_click',
  AMOUNT_CHANGE: 'amount_change',
  APPLE_PAY_CLICK: 'apple_pay_click',
})

/**
 * Home Page
 * @typedef {Object} HOME_PAGE_EVENTS
 */
export const HOME_PAGE_EVENTS = createEventsSection('ui:home_page', {
  PLAY_VIDEO_CLICK: 'play_video_click',
})

/**
 * @typedef {Object} ART_BOOK
 */
export const ART_BOOK = createEventsSection('ui:art_book', {
  SLIDER_INTERACTION: 'slider_interection',
  SAMPLE_BOOK_VIEW: 'sample_book_view',
  FAQ_ITEM_OPEN: 'faq_item_open',
})

/**
 * @typedef {Object} FRAMED_MOSAIC
 */
export const FRAMED_MOSAIC = createEventsSection('ui:framed_mosaic', {
  FAQ_ITEM_OPEN: 'faq_item_open',
})

/**
 * @typedef {Object} GIFT_CARDS
 */
export const GIFT_CARDS = createEventsSection('ui:gift_cards', {
  REDEEM_GIFT_CARD_OPEN: 'redeem_gift_card_open',
  REDEEM_GIFT_CARD_CLICK: 'redeem_gift_card_click',
  GET_PHYSICAL_CLICK: 'get_physical_click',
  GET_EGIFT_CARD_CLICK: 'get_egift_card_click',
  FAQ_ITEM_OPEN: 'faq_item_open',
  MODAL_NEXT_CLICK: 'modal_next_click',
  MODAL_CLOSE_CLICK: 'modal_close_click',
  CHECKOUT_NEXT_CLICK: 'checkout_next_click',
  AMOUNT_CHANGE: 'amount_change',
})

/**
 * @typedef {Object} MEMBERSHIP
 */
export const MEMBERSHIP = createEventsSection('ui:membership', {
  CHECKOUT_CLICK: 'checkout_click',
  MEMBERSHIP_CARD_CLICK: 'membership_card_click',
})

/**
 * @typedef {Object} OUR_PROCESS
 */
export const OUR_PROCESS = createEventsSection('ui:our_process', {
  PLAY_VIDEO_CLICK: 'play_video_click',
  TEAM_SLIDER_CLICK: 'team_slider_click',
})

/**
 * @typedef {Object} ABOUT_US
 */
export const ABOUT_US = createEventsSection('ui:about_us', {
  OTHER_PROJECTS_CLICK: 'other_projects_click',
})
