import React, { forwardRef } from 'react'

import MaskedInput from 'react-text-mask'
import { TextField } from '@material-ui/core'

const PhoneNumberField = forwardRef(({ InputProps, value, ...props }, ref) => {
  return (
    <MaskedInput
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      ref={ref}
      guide={false}
      render={(ref, field) => (
        <TextField
          inputRef={ref}
          InputProps={InputProps}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: 'numeric',
          }}
          value={value}
          {...field}
          {...props}
          type={'text'}
          autoComplete={'tel-national'}
        />
      )}
    />
  )
})

export default PhoneNumberField
