import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { capitalize } from '@material-ui/core/utils'

import ImageContainer from '../ImageContainer'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  imageWrapper: {
    borderRadius: 2,
    overflow: 'hidden',
    marginRight: theme.spacing(2),
    minWidth: ({ ratio }) => 130 * ratio,
    [theme.breakpoints.down('sm')]: {
      minWidth: ({ ratio }) => 90 * ratio,
    },
  },
  image: {
    maxHeight: '100%',
    width: 'auto',
    objectFit: ({ cover }) => cover ? 'cover' : 'fit',
  },
  information: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
  },
  titleBox: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    lineHeight: 1.2,
  },
  price: {
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    color: '#202126',
  },
  spacer: {
    flexGrow: 1,
  },
  qty: {
    padding: '0',
    justifySelf: 'flex-end',
  },
  membershipPlan: {
    fontSize: '.825rem',
    color: theme.palette.secondary.main,
  },
  effective: {
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
}))

const OrderProductPreview = ({ image, title, price, children, ratio }) => {
  const classes = useStyles({ ratio })

  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <ImageContainer src={image} className={classes.image} ratio={ratio} />
      </div>

      <div className={classes.information}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={classes.titleBox}>
          <Typography variant={'h5'} className={classes.title}>
            {capitalize(title)}
          </Typography>

          <Box display={'flex'}>
            <Typography variant={'body2'} classes={{ root: classes.price }}>
              {price}
            </Typography>
          </Box>
        </Box>

        {children}
      </div>
    </div>
  )
}

OrderProductPreview.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.node,
  children: PropTypes.node,
  ratio: PropTypes.number,
}

OrderProductPreview.defaultProps = {
  ratio: 4 / 3,
}

export default OrderProductPreview
