import React from 'react'

import { Box, Button, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainerBox from 'artkive/components/PageSections/ContainerBox'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ProductNotFoundImage from 'images/ecom/box/product_not_found.svg'
import DescriptionBubbles from 'images/ecom/get-started/bubbles.png'
import lines from 'images/ecom/get-started/lines_2.png'
import Lines2 from 'images/ecom/get-started/lines_3.svg'
import Product from 'images/ecom/get-started/product_2.png'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '96px',
    paddingTop: 0,
    width: '100%',
  },
  mainSection: {
    backgroundColor: '#E3F2FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  img: {
    width: 200,
    height: 'auto',
    margin: spacing(2),
  },
  productImage: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  bubblesLeft: {
    position: 'absolute',
    width: 167,
    height: 320,
    left: -11,
    top: -16,
  },
  bubblesRight: {
    position: 'absolute',
    width: 167,
    height: 320,
    right: -11,
    bottom: -16,
  },
  bubblesMobile: {
    position: 'absolute',
    width: 63,
    height: 122,
    right: -20,
    top: 30,
  },
  box: {
    backgroundColor: palette.common.white,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  productHeader: {
    textAlign: 'center',
    marginTop: spacing(8),
    marginBottom: spacing(6),
  },
  productImageWrapper: {
    position: 'relative',
  },
  lines: {
    position: 'absolute',
    width: 64,
    height: 64,
    top: -35,
    right: 25,
  },
  getLines: {
    position: 'absolute',
    width: 75,
    top: -50,
    left: 300,
  },
}))

const ProductNotFound = ({ boxPrice }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Box className={classes.root}>
      <Box pb={10} className={classes.mainSection}>
        <img src={ProductNotFoundImage} className={classes.img} />
        <Hidden smDown>
          <img src={DescriptionBubbles} className={classes.bubblesLeft} />
          <img src={DescriptionBubbles} className={classes.bubblesRight} />
        </Hidden>
        <Hidden mdUp>
          <img src={DescriptionBubbles} className={classes.bubblesMobile} />
        </Hidden>

        <Typography variant={'h2'} align={'center'}>
          Whoops! This Product Doesn&quot;t Exist.
        </Typography>
      </Box>
      <ContainerBox className={classes.root}>
        <Typography variant={'h4'} className={classes.productHeader}>
          Here is the start of Every Keepsake
        </Typography>
        <Paper elevation={1}>
          <Grid container>
            <Grid item xs={12} md={5} className={classes.productImageWrapper}>
              <img src={lines} className={classes.lines} />
              <img src={Product} className={classes.productImage} />
            </Grid>
            <Grid item xs={12} md={7} className={classes.box}>
              <Box px={isMobile? 2 : 8} py={4}>
                <Box mb={2}>
                  <Typography variant={'h5'} align={isMobile ? 'center' : 'justify'}>
                    Artkive Box
                  </Typography>
                </Box>
                <Box mb={4}>
                  <Typography align={isMobile ? 'center' : 'justify'}>
                    24”x18”x4” crush-proof Artkive Box that includes everything you need to send in your artwork.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant={'h4'} align={isMobile ? 'center' : 'justify'}>
                    ${boxPrice}
                  </Typography>
                </Box>

                <Box
                  display={'flex'}
                  justifyContent={isMobile? 'center': 'flex-start'}
                  position={'relative'}
                >
                  <Button
                    color={'primary'}
                    href={routes.boxCheckout}
                    variant={'contained'}
                    style={{ paddingLeft: 70, paddingRight: 70 }}
                  >
                    Get My Box
                  </Button>

                  <Hidden smDown>
                    <img src={Lines2} className={classes.getLines} />
                  </Hidden>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </ContainerBox>
    </Box>
  )
}

export default ProductNotFound
