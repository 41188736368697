import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Container, Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'wouter'

import Divider from 'artkive/components/Checkout/Divider'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import theme from 'artkive/utils/theme'

import AppSnackbars from '../AppSnackbar'
import { BackButton } from '../Header'
import OrderPriceSummary from '../OrderSummary/OrderPriceSummary'

import MobileHeader from './MobileHeader'
import StepperBox from './StepperBox'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    minHeight: '100vh',
    width: '100%',
  },
  mobileProgressRoot: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 53,
    zIndex: 5,
    height: 3,
    left: 0,
    width: '100%',
  },
  mobileTitle: {
    fontSize: '1rem',
  },
  mobileHeaderToolbar: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5,
    height: spacing(7),
    boxShadow: '0px 0px 10px rgba(16, 35, 113, 0.1)',
  },
  stepper: {
    marginBottom: spacing(5),
  },
  orderSummary: {
    backgroundColor: 'white',
    minHeight: '100vh',
    padding: '2rem',
    height: '100%',
  },
  content: {
    marginTop: spacing(3),
    [breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
}))

const CheckoutWrapper = ({
  product,
  details,
  paymentOptions,
  error,
  setError,
  backTo,
  children,
}) => {
  const { activeStep, visibleSteps } = useVariableState(product.type, product.uuid)

  const classes = useStyles()

  const currentIndex = useMemo(() => {
    return visibleSteps.findIndex((step) => step.alias === activeStep.alias)
  }, [visibleSteps, activeStep.alias])

  const prevStep = useMemo(() => {
    if (!activeStep) return null

    return visibleSteps[currentIndex - 1]
  }, [currentIndex, activeStep?.name])

  const backButtonProps = useMemo(() => {
    return prevStep ? {
      href: prevStep.index === 0 ? '/' : `/${prevStep.alias}`,
      disabled: !prevStep.isComplete && !prevStep.isDirty,
      component: Link,
    } : {
      href: backTo,
    }
  }, [prevStep])

  const completedValue = 100 / visibleSteps.length * (currentIndex + 1)

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={7}>
          <MobileHeader
            backButtonProps={backButtonProps}
            title={activeStep?.name}
            progress={visibleSteps?.length > 1 ? completedValue : null}
          />

          <Container>
            <Box position={'relative'} pb={paymentOptions ? 4 : 8} zIndex={4} className={classes.content}>
              <Hidden smDown>
                <Grid container alignItems={'center'} wrap={'nowrap'} className={classes.stepper}>
                  <Box>
                    <BackButton {...backButtonProps} />
                  </Box>

                  <Grid item xs={11}>
                    <Box display={'flex'} justifyContent={'center'} px={visibleSteps?.length === 2 ? 29 : 10}>
                      {!!visibleSteps?.length && (
                        <StepperBox
                          activeStep={currentIndex}
                          steps={visibleSteps}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Hidden>

              <AppSnackbars
                variant={'error'}
                message={error}
                onClose={() => setError(null)}
              />

              {details && (
                <Hidden mdUp>
                  <Box mb={3}>
                    {details}
                  </Box>
                </Hidden>
              )}

              {children}

            </Box>
          </Container>

          {paymentOptions}
        </Grid>

        <Grid item xs={12} md={5}>
          <Hidden smDown>

            <div className={classes.orderSummary}>
              <Box fontSize={'large'} fontWeight={'bold'} my={2}>
                Order Summary
              </Box>
              {details && (
                <>
                  {details}

                  <Divider />
                </>
              )}

              <OrderPriceSummary product={product} />
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  )
}

CheckoutWrapper.propTypes = {
  paymentOptions: PropTypes.node,
  product: PropTypes.object,
  details: PropTypes.object,
  error: PropTypes.string,
  setError: PropTypes.func,
  backTo: PropTypes.string,
  children: PropTypes.node,
}

export default CheckoutWrapper
