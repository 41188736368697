export const colors = {
  black: '#202126',
  secondary: {
    light: '#E3F2FD',
    med: '#86D1FF',
    main: '#50bdff',
    dark: '#009FFF',
  },
  primary: {
    light: '#1D40CF',
    med: '#1936AF',
    main: '#142C90',
    dark: '#102371',
  },
  sky: {
    100: '#F6F9FA',
    300: '#E6F1FC',
    400: '#DAEDFC',
    500: '#B2E2FF',
    700: '#009FFF',
    900: '#0A65C7',
  },
  blue: {
    800: '#102371',
  },
  green: {
    100: '#E1F6E8',
    500: '#B1D7BE',
    700: '#208E46',
    light: '#E0F5E9',
    main: '#208E46',
  },
  grey: {
    100: '#ECECEE',
    300: '#D6D6D6',
    700: '#707070',
    light: '#EBEBEB',
    med: '#D6D6D6',
    main: '#B1B1B1',
    dark: '#707070',
  },
  orange: {
    light: '#fce5cf',
    main: '#F49220',
  },
  pink: {
    light: '#f8e8f1',
    main: '#DA8AB9',
  },
  purple: {
    light: '#EAE7F2',
    med: '#e2deed',
    main: '#DDD9EA',
    dark: '#7A68AE',
    darker: '#6A56A6',
  },
  red: {
    light: '#f9d2db',
    main: '#CF1B45',
  },
  yellow: {
    light: '#fef6e1',
    med: '#FFF2D1',
    main: '#FDE4A5',
    dark: '#FBCE5F',
  },
  white: {
    med: '#FAFAFA',
    main: '#FFFFFF',
    dark: '#F5F9FA',
  },
  bg: {
    yellow: '#FDF1D4',
    sky: '#E6F1FC',
    blue: '#D3EFFB',
    pink_light: '#EDEBF4',
    pink_med: '#E9E6F1',
    pink_dark: '#E2DEED',
    purple: '#E3DFEE',
  },
}

export const newColors = {
  grey: {
    700: '#646778',
    900: '#3D4156',
  },
}

const shadows = {
  1: '0px 4px 10px rgba(16, 35, 113, 0.08)',
  2: '2px 4px 20px rgba(16, 35, 113, 0.1)',
  3: '0px 5px 20px rgba(16, 35, 113, 0.1)',
  4: '0px 0px 10px rgba(16, 35, 113, 0.1)',
  5: '5px 10px 30px rgba(18, 38, 109, 0.1)',
}

const spacings = {
  section: 12, // 96px,
  sectionMobile: 8,
}

const theme = {
  colors,
  shadows,
  spacings,
}

export default theme
