import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LocationOn } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import { useStoreState } from 'easy-peasy'
import throttle from 'lodash/throttle'

// script helpers
const loadScript = (src, position, id) => {
  if (!position) return

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = {
  current: null,
}
const placesService = {
  current: null,
}

// styled components
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const Address = forwardRef(({
  defaultValue,
  error,
  helperText,
  onChange,
  name,
}, _ref) => {
  const { env } = useStoreState(({ envStore }) => envStore)

  // state
  const classes = useStyles()
  const [address, setAddress] = useState({})
  const [value, setValue] = useState(defaultValue)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const fetch = useMemo(() => throttle((request, callback) => {
    autocompleteService.current.getPlacePredictions(request, callback)
  }, 200), [])

  useEffect(() => {
    if (typeof window === 'undefined' || !!document.querySelector('#google-maps') || !env.gapi_maps_client_key) {
      console.log('gapi search not available')
      return
    }

    const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${env.gapi_maps_client_key}&libraries=places`

    loadScript(mapsUrl, document.querySelector('head'), 'google-maps')
  }, [env.gapi_maps_client_key])

  useEffect(() => {
    let active = true

    // set autocomplete service
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    // set places service
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'))
    }
    if (!placesService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({
      componentRestrictions: { country: 'us' },
      input: inputValue,
      types: ['address'],
    }, (results) => {
      if (active) {
        let newOptions = []

        if (value) newOptions = [value]

        if (results) newOptions = [...newOptions, ...results]

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  useEffect(() => {
    if (!value || !value.place_id) return
    placesService.current.getDetails({
      placeId: value.place_id,
      fields: ['address_components'],
    }, (place, status) => {
      if (status === 'OK') {
        const addy = {
          address: '',
        }
        place.address_components.forEach((comp) => {
          // address
          if (comp.types[0] === 'street_number') {
            addy.address += comp.long_name
          }

          // address
          if (comp.types[0] === 'route') {
            addy.address += ` ${comp.long_name}`
          }

          // city
          if (comp.types[0] === 'locality') {
            addy.city = comp.long_name
          }

          // state
          if (comp.types[0] === 'administrative_area_level_1') {
            addy.state = comp.short_name
          }

          // zipcode
          if (comp.types[0] === 'postal_code') {
            addy.zipCode = comp.short_name
          }
        })

        setAddress(addy)
      }
    })
  }, [value])

  useEffect(() => {
    if (address.address) setValue(address.address)

    onChange(address)
  }, [address])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete={false}
      includeInputInList
      filterSelectedOptions
      freeSolo={true}
      onClose={(event, reason) => {
        if (reason === 'blur' || reason === 'escape') {
          setValue(event.target.value)
          setAddress({ ...address, address: event.target.value })
        }
      }}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={error}
          helperText={helperText}
          label={'Street'}
          variant={'outlined'}
          name={name}
          autoComplete={'shipping address-line1'}
          onChange={(e) => {
            onChange({
              address: e.target.value,
            })
          }}
        />
      )}

      renderOption={(option) => {
        const matches = option?.structured_formatting?.main_text_matched_substrings

        if (!matches) return null

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems={'center'}>
            <Grid item>
              <LocationOn className={classes.icon} />
            </Grid>

            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant={'body2'}>
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
})

Address.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
}

Address.defaultProps = {
  error: false,
  helperText: null,
  defaultValue: '',
  name: null,
}

export default Address
