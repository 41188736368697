/* eslint-disable no-useless-escape */
/**
 * List of globally used regular expressions
 */
export default {
  email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  password: /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/,
  name: /^[a-z &,.'-]+$/i,
  label: /^[a-z0-9 &,.'-]{2,25}$/i,
  child: /^([a-z0-9 &,.'-]{2,25})|(-)$/i,
  tag: /^[a-z0-9 &,.'-]{2,255}$/i,
  title: /^.{2,50}$/i,
  cardName: /^[a-z]+ [a-z]+$/i,
  cardNumber: /\d{4} \d{4} \d{4} \d{4}/,
  cardExpiry: /\d{2}\/\d{2}/,
  cardCvc: /\d{3}/,
  zipCode: /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/,
  street: /^[a-z0-9. -']{2,50}$/i,
}
