import { action, computed, thunk, thunkOn } from 'easy-peasy'

import request from 'common/utils/request'
import reset from 'artkive/stores/ecom/helpers/reset'
import { PACKAGE_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import BoxImg from 'images/ecom/checkout/Checout-Box-Thumbnail.jpg'

import { fetchPackagePrice } from '../api/fetchPrice'
import customStorage from '../customStorageEngine'

import { DEFAULT_STATE as USER_STATE } from './user.store'

const DEFAULT_STATE = {
  information: { ...USER_STATE.user },
  payment: {},
  price: {},
  pricePayload: {},
  product: {
    id: 0,
    image: BoxImg,
    previewImage: BoxImg,
    msrp: 0,
    price: 0,
    type: PACKAGE_PRODUCT_TYPE,
    isTaxable: true,
  },
  promoCode: null,
  qty: 1,
  isBookCopiesAvailable: false,
  steps: [],
  tax: 0,
  confirmation: {},
}

const resetter = reset({ defaultState: DEFAULT_STATE, keys: ['promoCode'] })

export const packageStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  activeStep: computed(() => ({
    name: 'Checkout',
    index: 0,
    isActive: true,
    isComplete: false,
    isVisible: true,
  })),

  visibleSteps: computed((state) => state.steps.filter((step) => !!step.isVisible)),

  freeOrder: computed(({ price }) => Object.keys(price).length > 0 ? price.total <= 0 : false),

  fullName: computed(({ information }) => [information.firstName, information.lastName].filter(Boolean).join(' ')),

  shippingPrice: computed(
    ({ price }) => price.shipping_price + price.processing_price + price.shipping_protection_price,
  ),

  addOns: computed(({ qty, isBookCopiesAvailable }) => {
    if (!isBookCopiesAvailable) {
      return []
    }

    return [
      { type: 'BOOK_COPIES', quantity: qty },
    ]
  }),

  // actions
  setInformation: action((state, payload) => {
    state.information = { ...state.information, ...payload }
  }),

  setIsBookCopiesAvailable: action((state, payload) => {
    state.isBookCopiesAvailable = payload
  }),

  setPayment: action((state, payload) => {
    state.payment = { ...state.payment, ...payload }
  }),

  setPricePayload: action((state, payload) => {
    state.pricePayload = payload
  }),

  setPrice: action((state, payload) => {
    state.price = { ...state.price, ...payload }
  }),

  setProduct: action((state, payload) => {
    state.product = { ...state.product, ...payload }
  }),

  setConfirmation: action((state, payload) => {
    state.confirmation = { ...payload }
  }),

  setPromoCode: action((state, payload) => {
    state.promoCode = payload
  }),

  setQty: action((state, payload) => {
    if (payload < 0 || payload >= 10) throw new Error('Quantity must be greater than 0 and less than 50')

    state.qty = payload
  }),

  setStepActive: action((state, { stepName }) => {
    const alias = stepName.toLowerCase()

    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, isActive: true }
      : { ...step, isActive: false })
  }),

  setStep: action((state, { stepName, payload }) => {
    const alias = stepName.toLowerCase()

    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, ...payload }
      : step)
  }),

  // thunks

  orderPackage: thunk(async (actions, payload) => {
    try {
      const response = await request.post(Routing.api_v2_orders_packages(), payload)

      return { response }
    } catch (error) {
      return { error }
    }
  }),

  fetchPrice: thunk(async (actions, payload) => {
    try {
      actions.setPricePayload(payload)
      const data = await fetchPackagePrice(payload)
      actions.setPrice(data)
    } catch (error) {
      return { error }
    }
  }),

  // listeners
  onItemsChange: thunkOn(
    (actions) => [
      actions.setInformation,
      actions.setProduct,
      actions.setPromoCode,
      actions.setQty,
      actions.setIsBookCopiesAvailable,
    ],
    async (actions, target, helpers) => {
      const {
        qty,
        pricePayload,
        price,
        promoCode,
        product,
        isBookCopiesAvailable,
      } = helpers.getState()

      if (!product.id)
        return

      const currentPricePayload = {
        package_order: {
          concierge_product_id:product.id,
          promo_code: promoCode,
          add_ons: [],
        },
      }

      if (isBookCopiesAvailable) {
        currentPricePayload.package_order.add_ons.push({
          type: 'BOOK_COPIES',
          quantity: qty,
        })
      }

      const isPriceLoaded = Object.keys(price).length
      if (JSON.stringify(currentPricePayload) !== JSON.stringify(pricePayload) || !isPriceLoaded) {
        console.log('actions.fetchPrice')
        await actions.fetchPrice(currentPricePayload)
      }
    },
  ),
  ...resetter,
}

export const persistOptions = { deny: ['payment', 'price'], storage: customStorage }

// packageStore
export default packageStore
