import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import OptionListItem from 'artkive/components/Checkout/OptionListItem'
import CheckboxControl from 'artkive/components/Checkout/Sections/CheckboxControl'
import HelpTooltip from 'artkive/components/Tooltip/HelpTooltip'
import formatNumber from 'artkive/utils/formatNumber'

const useBulletStyles = makeStyles(({ spacing }) => ({
  listItem: {
    lineHeight: '140%',
    marginTop: spacing(0.5),
  },
  listItemCaption: {
    fontWeight: 500,
    paddingLeft: spacing(1.5),
    textIndent: -spacing(1.5),
  },
  marker: {
    marginRight: spacing(1),
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
}))

const useStyles = makeStyles(({ breakpoints }) => ({
  helpIcon: {
    marginTop: '2px',
  },
  checkboxLabel: {
    marginRight: 0,
  },
  priceDescription: {
    fontSize: 10,

    [breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
}))

const Bullets = ({ bullets }) => {
  const classes = useBulletStyles()

  return (
    <Box component={'ul'} my={0} className={classes.list}>
      {bullets.map(({ text }) => (
        <li className={classes.listItem} key={text}>
          <Typography variant={'caption'} className={classes.listItemCaption} component={'div'}>
            <span className={classes.marker}>•</span>
            {text}
          </Typography>
        </li>
      ))}
    </Box>
  )
}

const AddonItem = ({ item, getAddon, setAddon, freeAddons, formatPrice, highlightable, dense }) => {
  const classes = useStyles()
  const addOn = useMemo(() => (
    getAddon(item)
  ), [item, getAddon])

  const selected = !!addOn
  const disabled = !!freeAddons[item.kind]

  const handleChange = ({ target }) => {
    setAddon({ addon: item, checked: target.checked })
  }

  return (
    <OptionListItem
      label={
        <CheckboxControl
          checked={selected}
          onChange={handleChange}
          disabled={disabled}
          classes={{ root: classes.checkboxLabel }}
          label={
            <>
              <Box display={'flex'} alignItems={'flex-start'}>
                <Typography variant={'body2'}>{item.name}</Typography>
                {!!item.tip && (
                  <HelpTooltip size={'large'} title={item.name} classes={{ anchor: classes.helpIcon }}>
                    <Typography variant={'body1'} styles={{ fontSize: '14px' }}>
                      {item.tip}
                    </Typography>
                  </HelpTooltip>
                )}
              </Box>
            </>
          }
        />
      }
      secondaryLabel={
        item.bullets && (
          <Box ml={4.5}>
            <Bullets bullets={item.bullets} />
          </Box>
        )
      }
      price={formatPrice(item.price)}
      active={selected}
      component={'div'}
      className={cl({ dense, highlightable })}
      priceDescription={item.price_description && (
        <Box textAlign={'right'} pt={0.5}>
          <Typography variant={'caption'} className={classes.priceDescription}>
            {item.price_description}
          </Typography>
        </Box>
      )}
    />
  )
}

AddonItem.propTypes = {
  item: PropTypes.object.isRequired,
  getAddon: PropTypes.func.isRequired,
  setAddon: PropTypes.func.isRequired,
  highlightable: PropTypes.bool,
  formatPrice: PropTypes.func,
  freeAddons: PropTypes.object,
  dense: PropTypes.bool,
}

AddonItem.defaultProps = {
  highlightable: true,
  formatPrice: (price) => +price ? `+ $${formatNumber(price)}` : `FREE`,
  freeAddons: {},
  dense: false,
}

export default AddonItem
