import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'
import { FormHelperText, Grid, TextField } from '@material-ui/core'

import PhoneNumberField from 'artkive/components/Forms/PhoneNumberField'
import { TrackerContext } from 'artkive/components/Tracker/TrackerContext'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import validations from 'artkive/utils/validations'

import { PHONE_NUMBER_TOS_HINT } from '../Information.constants'

const ContactInformationFragment = ({ errors }) => {
  const isMobile = useMobileBreakpoint()
  const { fire } = useContext(TrackerContext)

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      <Grid item xs={12} md={6}>
        <Controller
          name={'email'}
          defaultValue={''}
          rules={validations.email}
          render={({ field }) => (
            <TextField
              error={!!errors.email}
              helperText={errors?.email?.message}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'Email'}
              type={'email'}
              variant={'outlined'}
              {...field}
              onBlur={(e) => {
                field.onBlur(e)
                fire('blur')
              }}
              autoComplete={'email'}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name={'phone'}
          defaultValue={''}
          render={({ field }) => (
            <PhoneNumberField
              error={!!errors.phone}
              helperText={errors?.phone?.message}
              name={'phone'}
              margin={isMobile ? 'dense' : 'normal'}
              fullWidth={true}
              label={'Phone Number'}
              variant={'outlined'}
              {...field}
              onBlur={(e) => {
                field.onBlur(e)
                fire('blur')
              }}
            />
          )}
        />
        <FormHelperText style={{ marginTop: -4 }}>
          {PHONE_NUMBER_TOS_HINT}
        </FormHelperText>
      </Grid>
    </Grid>
  )
}

ContactInformationFragment.propTypes = {
  errors: PropTypes.object,
  productType: PropTypes.string,
  formType: PropTypes.string,
}

export default ContactInformationFragment
