import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add, Remove } from '@material-ui/icons'

import useTracker from '../Tracker/useTracker'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0',
    fontSize: '1rem',
    fontWeight: 500,
  },
  icon: {
    cursor: 'pointer',
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
  },
  decrease: {
    backgroundColor: '#E6F1FC',
    border: '2px solid #B2E2FF',
  },
  increase: {
    backgroundColor: '#E6F1FC',
    border: '2px solid #B2E2FF',
  },
  disabled: {
    backgroundColor: 'white',
    border: '2px solid #EBEBEB',
    color: '#B7B7B7',
    cursor: 'unset',
  },
}))

const MAX_PRODUCT_QTY = 10
const MIN_PRODUCT_QTY = 1

const Qty = forwardRef(
  (
    {
      setQty,
      size,
      qty,
      maxQty = MAX_PRODUCT_QTY,
      minQty = MIN_PRODUCT_QTY,
      className,
      trackerEvent,
      trackerOptions = {},
    },
    _ref,
  ) => {
    const classes = useStyles()
    const [action] = useTracker()

    const handleDecreaseClick = () => {
      if (qty > minQty) {
        if (trackerEvent) {
          action(trackerEvent, {
            qty: qty - 1,
            action: 'decrease',
            ...trackerOptions,
          })
        }
        setQty(qty - 1)
      }
    }
    const handleIncreaseClick = () => {
      const nextQty = Math.min(qty + 1, maxQty)
      if (trackerEvent) {
        action(trackerEvent, {
          qty: nextQty,
          action: 'increase',
          ...trackerOptions,
        })
      }
      setQty(nextQty)
    }

    return (
      <div className={cl(classes.root, className)}>
        <Remove
          color={'primary'}
          onClick={handleDecreaseClick}
          fontSize={size}
          className={cl('js-qty-decrease', classes.icon, classes.decrease, {
            [classes.disabled]: qty <= minQty,
          })}
        />

        <Box mx={1} fontSize={size} minWidth={16} textAlign={'center'}>
          {qty || 0}
        </Box>

        <Add
          color={'primary'}
          onClick={handleIncreaseClick}
          fontSize={size}
          className={cl('js-qty-increase', classes.icon, classes.increase, {
            [classes.disabled]: qty >= maxQty,
          })}
        />
      </div>
    )
  },
)

Qty.propTypes = {
  setQty: PropTypes.func,
  qty: PropTypes.number,
  maxQty: PropTypes.number,
  minQty: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
  trackerEvent: PropTypes.string,
  trackerOptions: PropTypes.object,
}

export default Qty
