import React, { memo, useEffect, useRef, useState } from 'react'

import { Box } from '@material-ui/core'

export const TrustIndexReviewsBox = memo(() => {
  const wrapperRef = useRef()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.trustindex.io/loader.js?9723b41373630430dd5bfbb1d7'
    script.async = true
    script.defer = true
    wrapperRef.current.appendChild(script)
  }, [])

  return (
    <Box ref={wrapperRef} />
  )
})

const TrustIndexReviews = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // TypeError: D.animate is not a function
    if (!('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))) {
      console.log('polyfill animate')

      const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/npm/web-animations-js@2.3.2'
      script.onload = () => {
        console.log('polyfill is ready')
        setIsReady(true)
      }
      document.head.appendChild(script)
    } else {
      setIsReady(true)
    }

  }, [])

  if (!isReady)
    return null

  return (
    <TrustIndexReviewsBox key={'trustscript'} />
  )
}

export default memo(TrustIndexReviews)
