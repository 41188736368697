import React from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core'

import { withNoSsr } from 'common/components/NoSsr'
import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PaymentInformationFragment from 'artkive/components/Checkout/Sections/PaymentInformationFragment'
import useFormWithRecaptcha from 'artkive/hooks/useFormWithRecaptcha'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableState } from 'artkive/hooks/useVariableStore'

const productType = 'membership'
const formType = 'payment'

const Payment = ({
  defaultValues = {},
  isLoading,
  onSubmit,
  product,
}) => {
  const isMobile = useMobileBreakpoint()
  const { price } = useVariableState(product.type, product.uuid)

  const {
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    ...methods
  } = useFormWithRecaptcha({
    defaultValues: {
      cardExp: '',
      cardCvc: '',
      cardNumber: '',
      name: '',
      billingZipCode: '',
      email: defaultValues.email || '',
      phone: defaultValues.phone || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    onSubmit,
  })

  const { formState: { errors, isValid }, watch } = methods

  const isConfirmDisabled = !(watch('tos') && isValid)

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        {recaptchaComponent}

        <Box my={4}>
          <Typography variant={'h6'}>Contact Information</Typography>

          <ContactInformationFragment
            productType={productType}
            errors={errors}
            formType={formType}
          />
        </Box>

        <Box mb={isMobile ? 0 : 2}>
          <Typography variant={'h6'}>
            Payment Information
          </Typography>
        </Box>

        <PaymentInformationFragment errors={errors} />

        <Box mt={6}>
          <Button
            color={'primary'}
            disabled={isLoading || isConfirmDisabled || !recaptchaLoaded}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          >
            Subscribe • ${price.total}
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

Payment.propTypes = {
  defaultValues: PropTypes.object,
  error: PropTypes.string,
  withReviewStep: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  product: PropTypes.object,
  setError: PropTypes.func,
}

export default withNoSsr(Payment)
