import { createTheme } from '@material-ui/core/styles'

import theme, { newColors } from './theme'

/**
 * @type {Theme}
 */
const defaultTheme = createTheme()

const muiTheme = createTheme({
  palette: {
    primary: {
      light: theme.colors.secondary.light,
      main: theme.colors.secondary.dark,
      dark: theme.colors.sky[900],
    },
    secondary: {
      light: theme.colors.grey.med,
      main: theme.colors.grey.dark,
      dark: theme.colors.black,
    },
    common: {
      black: theme.colors.black,
    },
    text: {
      secondary: newColors.grey[700],
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    button: {
      fontWeight: 'bold',
      fontSize: '1rem',
      textTransform: 'capitalize',
    },
    h1: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.4,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    h2: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.4,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
    h3: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    h4: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.85rem',
    },
    h5: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '700',
      fontSize: '1.25rem',
    },
    h6: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '700',
      fontSize: '1.125rem',
    },
    subtitle1: {
      color: theme.colors.black,
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.6,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    subtitle2: {
      color: theme.colors.black,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.4,
    },
    p: {
      color: theme.colors.grey.dark,
      fontSize: '1rem',
    },
    body1: {
      color: theme.colors.grey.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1rem',
      lineHeight: 1.6,
      fontWeight: 500,
    },
    body2: {
      color: theme.colors.grey.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    caption: {
      color: theme.colors.grey.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '0.8125rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
  },
  custom: {
    h7: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.25rem',
    },
    h8: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.15rem',
    },
    h9: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1rem',
    },
    h10: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '0.9rem',
    },
    h11: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '0.8rem',
    },
  },
  mixins: {
    toolbar:{
      minHeight: defaultTheme.spacing(7.5),

      [defaultTheme.breakpoints.up('md')]: {
        minHeight: defaultTheme.spacing(8),
      },
    },
  },
  overrides: {
    // buttons
    MuiButton: {
      root: {
        borderRadius: 8,
        padding: '12px 22px',
        fontSize: '1rem',
        lineHeight: 1.4,
      },
      contained: {
        boxShadow: 'none',
        '&.Mui-disabled': {
          color: theme.colors.white.main,
          backgroundColor: theme.colors.sky[500],
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
      textSizeSmall: {
        padding: '8px 0',
        fontSize: '0.875rem',
      },
      containedSizeLarge: {
        padding: '12px 64px',
        fontSize: '1rem',
        lineHeight: 1.5,
      },
      outlinedSizeLarge: {
        padding: '12px 64px',
        fontSize: '1rem',
        lineHeight: 1.5,
      },
      containedPrimary: {
        color: 'white',
        '&:hover': {
          color: 'white',
          backgroundColor: theme.colors.sky[900],
        },
      },
      outlinedPrimary: {
        color: theme.colors.sky[700],
        border: `2px solid ${theme.colors.sky[700]}`,
        '&:hover': {
          color: theme.colors.sky[900],
          border: `2px solid ${theme.colors.sky[900]}`,
          backgroundColor: theme.colors.white.main,
        },
        '&.Mui-disabled': {
          color: theme.colors.sky[500],
          border: theme.colors.sky[500],
        },
      },
    },
    // inputs
    MuiAutocomplete: {
      inputRoot: {
        backgroundColor: 'white',
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: 'white',
        fontWeight: 500,
      },
      multiline: {
        backgroundColor: 'white',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: 'white',
      },
    },
    // modal
    MuiDialog: {
      container: {
        backgroundColor: 'rgba(16, 35, 113, 0.6)',
      },
    },
    // slider
    MuiSlider: {
      thumb: {
        height: 32,
        width: 32,
        marginTop: -16,
        marginLeft: -16,
      },
      track: {
        height: 4,
      },
      rail: {
        height: 4,
      },
    },
    // alert
    MuiAlert: {
      standardSuccess: {
        color: theme.colors.green.main,
        backgroundColor: theme.colors.white.main,
      },
      standardInfo: {
        color: theme.colors.secondary.dark,
        backgroundColor: theme.colors.white.main,
      },
      standardWarning: {
        color: theme.colors.orange.main,
        backgroundColor: theme.colors.white.main,
      },
      standardError: {
        color: theme.colors.red.main,
        backgroundColor: theme.colors.white.main,
      },

      filledSuccess: {
        color: theme.colors.white.main,
        backgroundColor: theme.colors.green.main,
      },
      filledInfo: {
        color: theme.colors.white.main,
        backgroundColor: theme.colors.primary.main,
      },
      filledWarning: {
        color: theme.colors.white.main,
        backgroundColor: theme.colors.orange.main,
      },
      filledError: {
        color: theme.colors.white.main,
        backgroundColor: theme.colors.red.main,
      },
    },
    PrivateNotchedOutline: {
      legendLabelled: {
        '& > span': {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        whiteSpace: 'nowrap',
      },
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          color: theme.colors.secondary.dark,
        },
        '&$completed': {
          color: theme.colors.secondary.dark,
        },
      },
      active: {
        color: theme.colors.secondary.dark,
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        transform: 'translateX(8px)',
      },
      active: {},
      completed: {},
      line: {
        '$active &': {
          borderColor: theme.colors.secondary.dark,
        },
        '$completed &': {
          borderColor: theme.colors.secondary.dark,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: 'transparent',
        '&$active': {
          color: theme.colors.secondary.dark,
          '& circle': {
            stroke: 'unset',
          },
        },
        '& circle': {
          stroke: theme.colors.grey.med,
          strokeWidth: '1px',
        },
      },
      text: {},
      active: {
        color: 'white',
        '& $text': {
          fill: 'white',
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 2000,
      },
    },
  },
})

export default muiTheme
