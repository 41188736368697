import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, FormControlLabel, Grid, Link, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CCInput from 'common/components/CCInput'
import ExpDate from 'common/components/ExpDate'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import FreeOrderPaper from 'artkive/pages/Checkout/Steps/FreeOrderPaper'
import routes from 'artkive/utils/routes'
import validations from 'artkive/utils/validations'

const useStyles = makeStyles(({ breakpoints }) => ({
  leftPadding: {
    paddingLeft: 16,
    [breakpoints.down('sm')]: {
      paddingLeft: 8,
    },
  },
}))

const PaymentInformationFragment = ({ errors, disabled }) => {
  const isMobile = useMobileBreakpoint()
  const { unregister, register } = useFormContext()
  const classes = useStyles()

  const rules = useMemo(() => {
    if (disabled)
      return {}

    return {
      name: validations.name,
      cardNumber: validations.cardNumber,
      cardExp: validations.cardExp,
      cardCvc: validations.cardCvc,
      billingZipCode: validations.zipCode,
    }
  }, [disabled])

  useEffect(() => {
    const fields = [
      'name',
      'cardNumber',
      'cardExp',
      'cardCvc',
      'billingZipCode',
    ]

    if (disabled) {
      for (const field of fields) {
        unregister(field, {
          keepDefaultValue: true,
        })
      }
    } else {
      for (const field of fields) {
        register(field)
      }
    }
  }, [disabled])


  const { setValue } = useFormContext()

  const handleInputEvent = (e) => {
    setValue(
      e.target.getAttribute('name'),
      e.target.value,
      {
        shouldValidate: true,
      },
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          name={'name'}
          defaultValue={''}
          rules={rules.name}
          render={({ field }) => (
            <TextField
              error={!!errors.name}
              fullWidth={true}
              helperText={errors?.name?.message}
              label={'Name On Card'}
              margin={isMobile ? 'dense' : 'normal'}
              type={'text'}
              variant={'outlined'}
              disabled={disabled}
              {...field}
              autoComplete={'cc-name'}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name={'cardNumber'}
          defaultValue={''}
          rules={rules.cardNumber}
          render={({ field }) => (
            <TextField
              error={!!errors.cardNumber}
              fullWidth={true}
              helperText={errors?.cardNumber?.message}
              InputProps={{
                inputComponent: CCInput,
              }}
              label={'Card Number'}
              margin={isMobile ? 'dense' : 'normal'}
              type={'text'}
              disabled={disabled}
              {...field}
              variant={'outlined'}
              autoComplete={'cc-number'}
            />
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          name={'cardExp'}
          defaultValue={''}
          rules={rules.cardExp}
          render={({ field }) => (
            <TextField
              error={!!errors.cardExp}
              fullWidth={true}
              helperText={errors?.cardExp?.message}
              InputProps={{
                inputComponent: ExpDate,
              }}
              label={'Exp Date'}
              margin={isMobile ? 'dense' : 'normal'}
              placeholder={'MM/YY'}
              type={'text'}
              // inputProps={{ inputMode: 'numeric', maxLength: 4 }}
              disabled={disabled}
              {...field}
              variant={'outlined'}
              autoComplete={'cc-exp'}
            />
          )}
        />
      </Grid>

      <Grid item xs={4} right={'true'} className={classes.leftPadding}>
        <Controller
          name={'cardCvc'}
          defaultValue={''}
          rules={rules.cardCvc}
          render={({ field }) => (
            <TextField
              error={!!errors.cardCvc}
              fullWidth={true}
              helperText={errors?.cardCvc?.message}
              label={'CVC'}
              margin={isMobile ? 'dense' : 'normal'}
              type={'text'}
              inputProps={{ inputMode: 'numeric', maxLength: 4 }}
              disabled={disabled}
              {...field}
              variant={'outlined'}
              autoComplete={'cc-csc'}
            />
          )}
        />
      </Grid>

      <Grid item xs={4} className={classes.leftPadding}>
        <Controller
          name={'billingZipCode'}
          defaultValue={''}
          rules={rules.zipCode}
          render={({ field }) => (
            <TextField
              error={!!errors.billingZipCode}
              fullWidth={true}
              helperText={errors?.billingZipCode?.message}
              label={'Postal Code'}
              margin={isMobile ? 'dense' : 'normal'}
              disabled={disabled}
              {...field}
              type={'text'}
              variant={'outlined'}
              autoComplete={'postal-code'}
              onInput={handleInputEvent}
            />
          )}
        />
      </Grid>

      {disabled && (
        <FreeOrderPaper />
      )}

      <Grid item xs={12}>
        <Controller
          name={'tos'}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox color={'primary'} {...field} value={field.value} />}
              label={(
                <>
                  By checking this box you agree to our <Link href={routes.tou} target={'_blank'}>Terms of
                  Service</Link>.
                </>
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

PaymentInformationFragment.propTypes = {
  errors: PropTypes.object,
  disabled: PropTypes.bool,
}

export default PaymentInformationFragment
