import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Container,
  Grid,
  Hidden,
  Link,
  Paper,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Image from 'artkive/components/Image'
import Social from 'artkive/components/Social'
import withApp from 'artkive/hoc/withApp'
import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Dots from 'images/ecom/brand/dots-blue.svg'
import ContactUs from 'images/ecom/contact/ContactUs.jpg'

const ContactsDetails = ({ phone }) => (
  <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    <Box mb={4}>
      <Box mb={2}><Typography variant={'h4'}>Have Questions?</Typography></Box>
      <Typography>
        You can find detailed information on our <Link href={routes.faqs} target={'_blank'}>FAQ Page.</Link>
      </Typography>
    </Box>

    <Box mb={4}>
      <Box mb={2}>
        <Typography variant={'h4'}>Still Have Questions?</Typography>
      </Box>
      <Box mb={1}>
        <Typography>Email Us! <Link href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</Link></Typography>
      </Box>
      <Box mb={1}>
        <Typography>
          Text Us!{' '}
          <Link href={`sms:${phone.match(/^\+\d/) ? '' : '+1'}${phone.replace(/[^0-9]/g, '')}`}>{phone}</Link>
        </Typography>
      </Box>
      <Typography>
        Schedule a Call! <Link href={'https://calendly.com/artkive'} target={'_blank'}>Artkive Calendly</Link>
      </Typography>
    </Box>
  </Grid>
)
ContactsDetails.propTypes = {
  phone: PropTypes.string.isRequired,
}

const Contact = ({ phone }) => {
  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>

      {/* I CAN BE YOUR HERO BABAY */}
      <Box bgcolor={theme.colors.purple.med}>
        <Container style={{ alignItems: 'center', display: 'flex', minHeight: '400px', justifyContent: 'center', position: 'relative' }}>

          <Typography style={{ color: 'white', marginTop: isMobile ? '0' : '-15%' }} variant={'h1'}>Contact Us</Typography>

          <Hidden smDown>
            <Paper elevation={0} style={{ left: '0', bottom: '-100%', position: 'absolute', width: '100%' }}>
              <Box px={6} py={8}>
                <Grid container>
                  <Grid xs={12} md={6} item>
                    <Box textAlign={'center'} position={'relative'} px={8}>
                      <Box position={'relative'} style={{ zIndex: '2' }}><Image src={ContactUs} /></Box>
                      <Box
                        position={'absolute'}
                        left={'-12%'}
                        top={'18%'}
                        style={{ transform: 'rotate(90deg)', zIndex: '1' }}
                      >
                        <Image src={Dots} />
                      </Box>
                    </Box>
                  </Grid>
                  <ContactsDetails phone={phone} />
                </Grid>
              </Box>
            </Paper>
          </Hidden>
        </Container>
      </Box>
      {/* I CAN BE YOUR HERO BABAY */}

      <Container>
        <Box pb={10}>
          <Hidden mdUp>
            <Paper elevation={0}>
              <Box px={4}>
                <Grid container>
                  <Grid xs={12} md={6} item>
                    <Box textAlign={'center'} position={'relative'} py={8}>
                      <Box position={'relative'} style={{ zIndex: '2' }}><Image src={ContactUs} /></Box>
                      <Box
                        position={'absolute'}
                        left={'-32%'}
                        top={'25%'}
                        style={{ transform: 'rotate(90deg)', zIndex: '1' }}
                      >
                        <Image src={Dots} />
                      </Box>
                    </Box>
                  </Grid>
                  <ContactsDetails phone={phone} />
                </Grid>
              </Box>
            </Paper>
          </Hidden>

          <Hidden smDown>
            <Box my={10} py={10}>&nbsp;</Box>
          </Hidden>

        </Box>
      </Container>

      {/* Join The Fun  */}
      <Box mt={isMobile ? 0 : 10}>
        <Social />
      </Box>
    </Box>
  )
}
Contact.propTypes = {
  phone: PropTypes.string.isRequired,
}

export default withApp(Contact)
