import React from 'react'

import { SvgIcon } from '@material-ui/core'

const BooksIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d={'M17.5 5C15.55 5 13.45 5.4 12 6.5C10.55 5.4 8.45 5 6.5 5C4.55 5 2.45 5.4 1 6.5V21.15C1 21.8 1.73 21.6 1.75 21.6C3.1 20.95 5.05 20.5 6.5 20.5C8.45 20.5 10.55 20.9 12 22C13.35 21.15 15.8 20.5 17.5 20.5C19.15 20.5 20.85 20.8 22.25 21.55C22.66 21.76 23 21.36 23 21.1V6.5C21.51 5.38 19.37 5 17.5 5ZM21 19C19.9 18.65 18.7 18.5 17.5 18.5C15.8 18.5 13.35 19.15 12 20V8.5C13.35 7.65 15.8 7 17.5 7C18.7 7 19.9 7.15 21 7.5V19Z'}
        fill={'currentColor'}
      />
    </SvgIcon>
  )
}

export default BooksIcon
