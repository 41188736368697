import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import ApplePayButton from 'common/components/ApplePayButton'

import { startApplePaySession } from './utils'

const ApplePay = ({ children, getPrice, onAddressChange, onSubmit, setError, requestAddress, onClick, disabled }) => {
  const applePayBtn = useRef()

  const payClickHandler = useCallback(() => {
    if (disabled)
      return

    onClick && onClick()
    startApplePaySession(requestAddress, getPrice, onAddressChange, onSubmit, setError)
  }, [disabled, requestAddress, getPrice, onSubmit, onAddressChange, setError, onClick])

  return (
    <ApplePayButton theme={'dark'} ref={applePayBtn} onClick={payClickHandler} disabled={disabled}>
      {children}
    </ApplePayButton>
  )
}

ApplePay.propTypes = {
  getPrice: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  requestAddress: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ApplePay.defaultProps = {
  children: null,
  requestAddress: false,
  onClick: null,
  disabled: false,
}

export default ApplePay
