import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'
import { Box, Button, Typography } from '@material-ui/core'

import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PaymentInformationFragment from 'artkive/components/Checkout/Sections/PaymentInformationFragment'
import PersonalNameFragment from 'artkive/components/Checkout/Sections/PersonalNameFragment'
import ShippingAddressFragment from 'artkive/components/Checkout/Sections/ShippingAddressFragment'
import useInformationForm from 'artkive/components/Checkout/useInformationForm'
import AddressValidation from 'artkive/components/Modals/AddressValidation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import addressValidationService from 'artkive/utils/addressValidationService'
import formatNumber from 'artkive/utils/formatNumber'

// Box checkout shipping and payment fields
const ShippingAndPayment = ({ onSubmit, product, options, isLoading, setError, captcha }) => {
  const [suggestion, setSuggestion] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const { payment, price, freeOrder } = useVariableState(product.type, product.uuid)
  const { setStep, setInformation } = useVariableActions(product.type, product.uuid)
  const isMobile = useMobileBreakpoint()

  const handleSubmit = useCallback(async (values) => {
    if (!values.isValid && options.shipping_address) {
      const { error, response } = await addressValidationService.checkAddress(values)

      if (response) {
        setShowModal(true)
        return setSuggestion(response)
      } else if (error) {
        return setError(error)
      }
    }

    onSubmit(values)
  }, [options, onSubmit])

  const {
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    ...methods
  } = useInformationForm(product, handleSubmit, captcha)
  const { setValue, formState: { errors, isValid }, watch, reset, getValues } = methods
  const isConfirmDisabled = !watch('tos') || !(isValid || freeOrder)

  const handleAddressSelection = useCallback(async (values) => {
    await setInformation(values)
    onSubmit(Object.assign(getValues()))
  }, [onSubmit, getValues])

  useEffect(() => {
    const paymentValues = {
      cardExp: '',
      cardCvc: '',
      cardNumber: '',
      name: '',
      zipCode: '',
      ...payment,
      tos: payment.tos || false,
    }
    reset(paymentValues, { keepValues: true })
    setStep({ stepName: BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT, payload: { isDirty: true } })
  }, [reset, setValue])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        {recaptchaComponent}

        <Box mb={2}>
          <Typography variant={'h6'}>Contact Information</Typography>
        </Box>

        <ContactInformationFragment errors={errors} productType={product.type} formType={'information'} />

        {options.shipping_address ? (
          <>
            <Box mb={isMobile ? 1 : 2} mt={3}>
              <Typography variant={'h6'} mt={4}>Shipping Address</Typography>
            </Box>

            <PersonalNameFragment />

            <ShippingAddressFragment errors={errors} countries={options.countries} />
          </>
        ) : (
          <PersonalNameFragment />
        )}

        <Box mb={isMobile ? 1 : 2} mt={3.5}>
          <Typography variant={'h6'}>Payment Information</Typography>
        </Box>

        <PaymentInformationFragment errors={errors} disabled={freeOrder} />

        <Box mt={6} mb={isMobile ? 0 : 7} display={'flex'} justifyContent={'space-around'}>
          <Button
            color={'primary'}
            disabled={isLoading || isConfirmDisabled || !recaptchaLoaded}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
          >
            Place Order {!!price.total && (
              <>
                • ${formatNumber(price.total)}
              </>
            )}
          </Button>
        </Box>
      </form>

      {suggestion && (
        <AddressValidation
          isOpen={showModal}
          onSubmit={handleAddressSelection}
          setIsOpen={setShowModal}
          suggestion={suggestion}
        />
      )}
    </FormProvider>
  )
}

ShippingAndPayment.propTypes = {
  product: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  captcha: PropTypes.bool.isRequired,
}

export default ShippingAndPayment
