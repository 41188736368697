import React from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'
import { Box, Button, Typography } from '@material-ui/core'

import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PersonalNameFragment from 'artkive/components/Checkout/Sections/PersonalNameFragment'
import useInformationForm from 'artkive/components/Checkout/useInformationForm'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const ContactInformation = ({ onSubmit, productType, product }) => {
  const isMobile = useMobileBreakpoint()
  const { handleFormSubmit, ...methods } = useInformationForm(product, onSubmit)
  const { formState: { errors, isValid } } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Box mb={2}>
          <Typography variant={'h6'}>Contact Information</Typography>
        </Box>

        <PersonalNameFragment />

        <ContactInformationFragment
          errors={errors}
          productType={productType}
          formType={'information'}
        />

        <Box mt={6}>
          <Button
            color={'primary'}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
            disabled={!isValid}
          >
            Continue To Payment
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

ContactInformation.propTypes = {
  onSubmit: PropTypes.func,
  productType: PropTypes.string,
  product: PropTypes.object,
}

export default ContactInformation
