/*
SEGMENT TRACKING
- wraps segment analytics api
- handles tracking with internal conditionals handled by helper methods
- in cases can dispatch other custom api tracking events
- all subsequent tracking is emitted from segment, this is our sole tracking system
*/

import * as Sentry from '@sentry/react'
import { detect } from 'detect-browser'

import request from 'common/utils/request'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import { validateEmail } from 'artkive/utils/validations'

const browser = detect()

const TRACKING_META = typeof window === 'undefined'
  ? {}
  : {
    browserType: browser.type,
    deviceOs: browser.os,
    env: window.app?.envType || 'development',
    trackingCategory: 'ecom',
  }

const handleError = (err, msg = 'Error tracking event failed') => {
  console.error(msg, JSON.stringify(err))

  Sentry.withScope(function(scope) {
    // group errors together based on their request and response
    scope.setFingerprint(['analytics', err.name])
    Sentry.captureException(err)
  })

  return err
}

const SESSION_TRACKING_KEY = 'artkive[tracking]'

const handleOncePerSession = (type, productId, cb) => {
  const data = sessionStorage.getItem(SESSION_TRACKING_KEY)
  let dataObj = {}
  if (data) {
    try {
      dataObj = JSON.parse(data)
    } catch (error) {
      console.error(error)
    }
  }
  const key = `${type}#${productId || ''}`

  if (!dataObj[key]) {
    sessionStorage.setItem(SESSION_TRACKING_KEY, JSON.stringify({ ...dataObj, [key]: 1 }))
    cb()
  } else {
    console.log('session exists, not setting')
  }
}

/**
 *
 * @param {function} func callback that should be executed if tracking is enabled
 * @param {function} callback callback that should be executed after tracking regardless environment
 */
const handleTracking = async (func, callback) => {
  if (window && window.app.envName !== 'test' && !window.app.admin && browser.type === 'browser') {
    if (!analytics || analytics.loadFailure) {
      handleError(new Error('Error: analytics is not present or initialized'))
    } else {
      try {
        await func()
      } catch (err) {
        handleError(err)
      }
    }
  }

  typeof callback === 'function' && await callback()

  return null
}

const priceMeta = ({ amount }) => ({
  amount,
  currency: 'USD',
  price: amount,
  value: amount,
})

/*
dispatches both analytics and api post requests
*/
export const captureEmail = ({
  email,
  phone,
  firstName,
  lastName,
  formType,
  kind,
  product,
  productId = null,
}) => handleTracking(async () => {
  if (!validateEmail(email)) return console.warn(`Error: email of '${email}' is invalid`)

  identifyUser({ email, name: [firstName, lastName].filter(Boolean).join(' ') })

  analytics.track('email capture', { email, formType, product, productId, ...TRACKING_META })
}, () => {
  if (!validateEmail(email)) return

  return request.post(Routing.api_v1_create_new_marketing_list_item(), {
    email,
    phone,
    kind,
    first_name: firstName,
    last_name: lastName,
  })
})

export const identifyUser = ({
  email,
  name,
  ...rest
}) => handleTracking(() => {
  if (!validateEmail(email)) return console.warn(`Error: email of '${email}' is invalid`)

  analytics.identify(email, {
    email,
    name,
    ...rest,
    ...TRACKING_META,
  })
})

const specialProductEventPrefix = (productType) => (
  productType !== BOX_PRODUCT_TYPE ? `${productType}: ` : ''
)

export const initiateCheckout = ({
  amount,
  discount,
  email,
  name,
  product,
  promo,
  productId = null,
}) => handleTracking(() => {
  const pricingMeta = priceMeta({ amount })
  const eventName = `${specialProductEventPrefix(product)}initiate checkout`
  handleOncePerSession(product, productId, () => analytics.track(eventName, {
    discount,
    email,
    name,
    product,
    productId,
    promo,
    ...pricingMeta,
    ...TRACKING_META,
  }))
})

export const page = ({ ...attrs } = {}) => handleTracking(() => {
  analytics.page('ecom', { ...attrs, ...TRACKING_META })
})

// NOTE: should be invoked before server side tracking so FB can deduplicate events
export const purchase = ({
  amount,
  discount,
  productId,
  name,
  orderId,
  product,
  promo,
  paymentKind,
  tracking = {},
  information = {},
  context = {},
}, callback) => handleTracking(async () => {
  const pricingMeta = priceMeta({ amount })
  const eventName = `${specialProductEventPrefix(product)}purchase`

  if (product === BOX_PRODUCT_TYPE) {
    // NOTE: ShareASale requires a specific event
    analytics.track(
      'Order Completed',
      { orderId, total: amount, amount },
      { integrations: { All: false, ShareASale: true } },
    )
  }

  return analytics.track(eventName, {
    discount,
    email: information.email,
    name,
    orderId,
    product,
    promo,
    productId,
    paymentKind,
    utmSource: tracking.utmSource,
    utmCampaign: tracking.utmCampaign,
    utmMedium: tracking.utmMedium,
    utmContent: tracking.utmContent,
    ...pricingMeta,
    ...TRACKING_META,
  }, context)
}, callback)

// initial tracking. should only be used once per react render
export const load = ({ user, ...attrs }) => handleTracking(() => {
  analytics.page('ecom', { ...attrs, ...TRACKING_META })

  if (typeof user === 'object' && Object.keys(user).length > 1 && !user.admin) {
    analytics.identify(user.email, {
      email: user.email,
      name: user.name,
      uuid: user.uuid,
    })
  }
})

export const action = (event, options) => {
  console.info(`%c tracker event: ${event}`, 'background: #222; color: #bada55', options)
  if (!event) {
    console.warn('No event specified for track action')
    return
  }

  return handleTracking(() => (
    analytics.track(event, {
      ...TRACKING_META,
      env: process.env.AWS_ENV,
      ...options,
    })
  ))
}

export const extractUtmParameters = (params) => {
  const source = params.get('utm_source')
  if (!source) return null

  return {
    utmSource: source,
    utmMedium: params.get('utm_medium'),
    utmCampaign: params.get('utm_campaign'),
    utmContent: params.get('utm_content'),
  }
}
