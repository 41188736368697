import { useCallback, useEffect } from 'react'

import memoizeDebounce from 'common/utils/memoizeDebounce'
import useFormWithRecaptcha from 'artkive/hooks/useFormWithRecaptcha'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'

const getName = (name) => name

const useGenericInformationForm = (product, watchableFields, formProps = {}) => {
  const methods = useFormWithRecaptcha({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
    ...formProps,
  })

  const { setValue, watch } = methods

  const { setInformation } = useVariableActions(product.type, product.uuid)
  const { information } = useVariableState(product.type, product.uuid)

  const debouncedSetInformation = useCallback(memoizeDebounce((name, value) => {
    setInformation({ [name]: value })
  }, 300, getName), [setInformation])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (information[name] !== value[name]) {
        debouncedSetInformation(name, value[name])
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, information, debouncedSetInformation])

  useEffect(() => {
    const fields = watchableFields

    for (const field of fields) {
      if (information[field]) {
        setValue(field, information[field], { shouldValidate: true })
      }
    }
  }, [watchableFields, information, setValue])

  return [information, methods]
}

export default useGenericInformationForm
