import { useEffect } from 'react'

import countries from 'common/utils/data/countries'
import states from 'common/utils/data/states'

import useGenericInformationForm from './useGenericInformationForm'

const watchableFields = [
  'email',
  'phone',
  'firstName',
  'lastName',
  'address',
  'address2',
  'city',
  'zipCode',
  'recipientFirstName',
  'recipientLastName',
  'toEmail',
  'deliveryDate',
  'note',
  'billingZipCode',
]

const useInformationForm = (product, onSubmit, recaptcha = false) => {
  const [information, methods] = useGenericInformationForm(product, watchableFields, {
    enabled: recaptcha,
    onSubmit,
  })

  const { setValue } = methods

  useEffect(() => {
    if (information.country) {
      const activeCountry = countries.find((state) => state.value === information.country)

      if (activeCountry) {
        setValue('country', activeCountry.value, {
          shouldValidate: true,
        })
      }
    }

    if (information.country && information.state) {
      const activeState = states[information.country].find((state) => state.value === information.state)

      if (activeState) {
        setValue('state', activeState.value, {
          shouldValidate: true,
        })
      }
    }

  }, [information, setValue])

  return methods
}

export default useInformationForm
