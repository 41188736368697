import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Controller, useFormContext } from 'react-hook-form'
import cl from 'classnames'
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import allCountries from 'common/utils/data/countries'
import states from 'common/utils/data/states'
import Address from 'artkive/components/Address'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import validations from 'artkive/utils/validations'

const useStyles = makeStyles(({ breakpoints }) => ({
  leftPadding: {
    paddingLeft: 16,
    [breakpoints.down('sm')]: {
      paddingLeft: 8,
    },
  },
}))

const ShippingAddressFragment = ({ errors, countries }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()
  const { setValue, unregister, watch } = useFormContext()
  const watchedCountry = watch('country')

  const countryOptions = useMemo(() => {
    return countries && allCountries.filter(({ value }) => countries?.includes(value))
  }, [countries])
  const selectedCountry = countryOptions ? watchedCountry : 'US'

  const handleAddressChange = (address) => {
    for (const field in address) {
      setValue(field, address[field], {
        shouldValidate: true,
      })
    }
  }

  useEffect(() => {
    return () => {
      unregister([
        'address',
        'address2',
        'country',
        'state',
        'city',
        'zipCode',
      ])
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countryOptions?.length === 1) {
        setValue('country', countryOptions[0].value)
      }
    }, 0)
    return () => clearTimeout(timeout)
  }, [countryOptions])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            name={'address'}
            defaultValue={''}
            shouldUnregister
            rules={validations.address}
            render={({ field: { onChange, value, ...field } }) => (
              <Address
                error={!!errors.address}
                helperText={errors?.address?.message}
                isMobile={isMobile}
                onChange={handleAddressChange}
                defaultValue={value}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Controller
            name={'address2'}
            defaultValue={''}
            shouldUnregister
            render={({ field }) => (
              <TextField
                fullWidth={true}
                label={'Apartment, Suite, etc. (optional)'}
                type={'text'}
                {...field}
                autoComplete={'shipping address-line2'}
                variant={'outlined'}
              />
            )}
          />
        </Grid>

        {countryOptions && (
          <Grid item xs={12} md={4}>
            <FormControl variant={'outlined'} fullWidth={true}>
              <InputLabel>Country</InputLabel>
              <Controller
                name={'country'}
                defaultValue={''}
                shouldUnregister
                render={({
                  fieldState: { invalid, error },
                  field,
                }) => (
                  <>
                    <Select
                      {...field}
                      error={!!invalid}
                      label={'Country'}
                      autoComplete={'shipping country'}
                    >
                      {countryOptions.map((country) => (
                        <MenuItem key={country.value} value={country.value}>{country.label}</MenuItem>
                      ))}
                    </Select>
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
                rules={validations.country}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={countryOptions ? 8 : 4}>
          <Box>
            <FormControl variant={'outlined'} fullWidth={true}>
              <InputLabel>State/Province</InputLabel>
              <Controller
                name={'state'}
                defaultValue={''}
                shouldUnregister
                render={({
                  fieldState: { invalid, error },
                  field,
                }) => (
                  <>
                    <Select
                      {...field}
                      disabled={countryOptions && !selectedCountry}
                      error={!!invalid}
                      label={'State/Province'}
                      autoComplete={'shipping address-level1'}
                    >
                      {(states[selectedCountry] ?? []).map((state) => (
                        <MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
                      ))}
                    </Select>
                    {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </>
                )}
                rules={validations.state}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={7} md={countryOptions ? 8 : 4} className={cl({ [classes.leftPadding]: !isMobile })}>
          <Controller
            name={'city'}
            defaultValue={''}
            shouldUnregister
            rules={validations.city}
            render={({ field }) => (
              <TextField
                autoComplete={'shipping address-level2'}
                error={!!errors.city}
                fullWidth={true}
                helperText={errors?.city?.message}
                label={'City'}
                type={'text'}
                {...field}
                variant={'outlined'}
              />
            )}
          />
        </Grid>

        <Grid item xs={5} md={4} className={classes.leftPadding}>
          <Controller
            name={'zipCode'}
            defaultValue={''}
            shouldUnregister
            rules={validations.zipCode}
            render={({ field }) => (
              <TextField
                autoComplete={'shipping postal-code'}
                error={!!errors.zipCode}
                fullWidth={true}
                helperText={errors?.zipCode?.message}
                label={'Postal Code'}
                type={'text'}
                {...field}
                // NOTE: this is required for Zip5+4
                inputProps={{ maxLength: 10 }}
                variant={'outlined'}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

ShippingAddressFragment.propTypes = {
  errors: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.string),
}

export default ShippingAddressFragment
