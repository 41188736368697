import React, { useMemo, useState } from 'react'

import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'

import useWindowSize from 'common/hooks/useWindowSize'
import Image from 'artkive/components/Image'
import MobileMenu from 'artkive/components/MobileMenu'
import OurProductsNavMenu from 'artkive/components/OurProductsNavMenu'
import {
  GET_BOX_CLICK,
  NAVIGATION,
  REFERRAL_MODAL,
} from 'artkive/constants/tracker/mainNavigation'
import useReferralCta from 'artkive/hooks/useReferralCta'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import artkiveLogo from 'images/ecom/nav/artkiveLogo.svg'

import { TrackButton, TrackLink, withClickTracker } from '../Tracker'

import AccentLink from './AccentLink'
import BooksIcon from './BooksIcon'
import NavDropdownMenu, { MenuItem } from './NavDropdownMenu'
import OrderTrackingIcon from './OrderTrackingIcon'

const useStyles = makeStyles((muiTheme) => ({
  offset: muiTheme.mixins.toolbar,
  navigationWrapper: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '1rem',
  },
  restMenu: {
    marginRight: -16,
    marginLeft: 8,
  },
  orderTracking: {
    fontWeight: 500,
    color: theme.colors.green.main,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.colors.white.dark,
    },
  },
  nftWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: 14,
    '&:hover': {
      backgroundColor: '#EDEBF4',
    },
  },
  navLink: {
    alignSelf: 'center',
    lineHeight: 1.5,
    fontWeight: 500,
    '&:hover': {
      alignSelf: 'stretch',
      borderRadius: 0,
    },
  },
  navigationItem: {},
  navIcon: {
    marginRight: 8,
  },
  navIconWrapper: {},
  logo: {
    width: 140,
    minWidth: 140,
    [muiTheme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  logoWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    [muiTheme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    '&>$logoBtn': {
      backgroundColor: 'unset',
    },
  },
  logoBtn: {},
  menuButton: {
    position: 'absolute',
    right: 16,
  },
  getStarted: {
    height: 48,
    width: 130,
  },
}))

const TopBar = withStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.main,
    boxShadow: theme.shadows[4],
  },
}))(AppBar)

const TrackAccentLink = withClickTracker(AccentLink)

const navigationOptions = () => {
  return {
    section: '',
  }
}

const useNavMenuAvailableCounter = () => {
  const { width } = useWindowSize()

  return useMemo(() => {
    // how many items should be shown for screen less then given value
    const visible = {
      600: 0,
      1060: 3,
      1216: 4, // 1436
      1374: 5,
      1433: 6,
    }

    const entries = Object.entries(visible)
    const last = entries.find(([w]) => w > width)

    return last ? last[1] : 10
  }, [width])
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const visibleItems = useNavMenuAvailableCounter()
  const openReferralModal = useReferralCta()
  const classes = useStyles()

  const menuItems = useMemo(() => [
    <MenuItem
      key={'order-tracking'}
      underline={'none'}
      component={TrackLink}
      href={routes.orderTracking}
      classes={{
        root: classes.orderTracking,
      }}
      onMouseEnter={() => setIsOpen(false)}
    >
      <Box
        component={'span'}
        display={'inline-flex'}
        alignItems={'center'}
        marginRight={1}
        marginTop={-0.5}
      >
        <OrderTrackingIcon />
      </Box>
      Track My Order
    </MenuItem>,
    <TrackAccentLink
      key={'get-20'}
      className={classes.navLink}
      onClick={openReferralModal}
      trackerEvent={REFERRAL_MODAL.CTA_CLICK}
    >
      Get $20
    </TrackAccentLink>,
    <MenuItem
      key={'our-products'}
      trackerEvent={NAVIGATION.KEEPSAKES_CLICK}
      underline={'none'}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      component={TrackLink}
    >
      Our Products{' '}
      <Box
        component={'span'}
        display={'inline-flex'}
        alignItems={'center'}
        marginLeft={1}
      >
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Box>
    </MenuItem>,

    <MenuItem
      key={'pricing'}
      trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
      underline={'none'}
      href={routes.pricing}
      component={TrackLink}
    >
      Pricing
    </MenuItem>,

    <NavDropdownMenu
      key={'how-it-works'}
      anchor={({ open, ...rest }) => (
        <MenuItem component={Button} {...rest}>
          How It Works
          <Box
            component={'span'}
            display={'inline-flex'}
            alignItems={'center'}
            marginLeft={1}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </MenuItem>
      )}
    >
      <MenuItem
        key={'our-process'}
        trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
        underline={'none'}
        href={routes.ourProcess}
        component={TrackLink}
      >
        Our Process
      </MenuItem>
      <MenuItem
        key={'about-us'}
        trackerEvent={NAVIGATION.ABOUT_US_CLICK}
        underline={'none'}
        href={routes.aboutUs}
        component={TrackLink}
      >
        About Us
      </MenuItem>
      <MenuItem
        key={'faqs'}
        trackerEvent={NAVIGATION.FAQS_CLICK}
        underline={'none'}
        href={routes.faqs}
        component={TrackLink}
      >
        FAQs
      </MenuItem>
    </NavDropdownMenu>,
    <MenuItem
      key={'cards-by-artkive'}
      trackerEvent={NAVIGATION.CARDS_CLICK}
      underline={'none'}
      href={routes.cardsByArtkive}
      component={TrackLink}
    >
      Cards by Artkive
    </MenuItem>,
    <MenuItem href={routes.giftCards} component={TrackLink} key={'gift-cards'}>
      Gift Cards
    </MenuItem>,

    // <MenuItem
    //   key={'plans'}
    //   trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
    //   underline={'none'}
    //   href={routes.plans}
    //   component={TrackLink}
    // >
    //   Memberships
    // </MenuItem>,
    // <MenuItem
    //   key={'nft'}
    //   underline={'none'}
    //   href={routes.nft}
    //   component={TrackLink}
    // >
    //   NFTs
    // </MenuItem>,
  ], [isOpen])

  const hiddenMenuItems = useMemo(() => {
    let items = menuItems.slice(visibleItems)
    const howItWorksElIndex = items.findIndex((el) => el.key === 'how-it-works')
    if (howItWorksElIndex > -1) {
      const el = items[howItWorksElIndex]
      items.splice(howItWorksElIndex, 1, el.props.children)
    }

    return items
  }, [menuItems, visibleItems])

  return (
    <Box position={'relative'} id={'Navbar-react-component'}>
      <TopBar position={'fixed'}>
        <Toolbar onMouseEnter={() => setIsOpen(false)}>
          <Box display={'flex'} className={classes.logoWrapper}>
            <MenuItem
              href={'/'}
              onMouseEnter={() => setIsOpen(false)}
              trackerEvent={NAVIGATION.LOGO_CLICK}
              trackerOptions={navigationOptions}
              component={TrackLink}
              className={classes.logoBtn}
            >
              <Image src={artkiveLogo} className={classes.logo} />
            </MenuItem>
          </Box>

          <Hidden smDown>
            <Box bgcolor={'white'} className={classes.navigationWrapper}>
              {menuItems.slice(0, visibleItems)}

              <MenuItem
                underline={'none'}
                onMouseEnter={() => setIsOpen(false)}
                href={routes.accountBooks}
                component={TrackLink}
              >
                <Box
                  component={'span'}
                  display={'inline-flex'}
                  alignItems={'center'}
                  marginRight={1}
                  marginTop={-0.5}
                >
                  <BooksIcon />
                </Box>{' '}
                My Books
              </MenuItem>

              <Box display={'inline-flex'} alignItems={'center'} marginLeft={2}>
                <TrackButton
                  color={'primary'}
                  href={routes.boxCheckout}
                  variant={'outlined'}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{
                    section: 'header',
                  }}
                  className={classes.getStarted}
                >
                  Get My Box
                </TrackButton>
              </Box>

              {visibleItems < menuItems.length && (
                <NavDropdownMenu
                  key={'how-it-works'}
                  anchor={({ open, ...rest }) => (
                    <MenuItem component={Button} {...rest} endIcon={<MenuIcon />} className={classes.restMenu} />
                  )}
                >
                  {hiddenMenuItems}
                </NavDropdownMenu>
              )}
            </Box>
          </Hidden>

          <Hidden mdUp>
            <IconButton
              aria-label={'menu'}
              className={classes.menuButton}
              style={{ color: theme.colors.secondary.dark }}
              edge={'end'}
              fontSize={'large'}
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              {mobileOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>
      </TopBar>

      {/* keepsakes dropdown menu */}
      <Hidden smDown>
        <OurProductsNavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </Hidden>

      <div className={classes.offset} />

      <Hidden mdUp>
        <MobileMenu isOpen={mobileOpen} onNavigate={() => setMobileOpen(false)} />
      </Hidden>
    </Box>
  )
}

export default Navbar
