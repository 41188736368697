import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Divider from 'artkive/components/Checkout/Divider'
import OrderPriceSummary from 'artkive/components/OrderSummary/OrderPriceSummary'
import Promo from 'artkive/components/Promo'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import OrderDetailsAddons from './OrderDetailsAddons'

const useStyles = makeStyles({
  continueButton: {
    textTransform: 'unset',
  },
})

const OrderDetails = ({ product, onSubmit, options, continueButtonText }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  const { setStep } = useVariableActions(BOX_PRODUCT_TYPE, product.uuid)
  const { vipSubscription } = useVariableState(BOX_PRODUCT_TYPE, product.uuid)

  useEffect(() => {
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isDirty: true } })
  }, [])

  return (
    <>
      <OrderDetailsAddons options={options} product={product} />

      {product && (
        <Hidden mdUp>
          <Divider my={3.5} />

          <Promo product={product} disabled={vipSubscription} />

          <Divider my={2.5} />

          <OrderPriceSummary product={product} />
        </Hidden>
      )}

      <Box mt={6} display={'flex'} justifyContent={'space-around'}>
        <Button
          color={'primary'}
          fullWidth={isMobile}
          onClick={onSubmit}
          size={'large'}
          variant={'contained'}
          classes={{ root: classes.continueButton }}
        >
          {continueButtonText}
        </Button>
      </Box>
    </>
  )
}

OrderDetails.propTypes = {
  continueButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  product: PropTypes.object,
  options: PropTypes.object,
}

export default OrderDetails
