import { action, thunk } from 'easy-peasy'

import { MODALS } from 'artkive/components/Modals/constants'

const urlParamName = 'modal'

const DEFAULT_STATE = {
  ui: {
    loader: false,
  },
  modal: null,
}

const uiStore = {
  // state
  ...DEFAULT_STATE,

  // actions
  setLoader: action((state, bool) => {
    if (typeof bool === 'undefined') console.error('Error: setLoader requires a boolean option')

    state.ui.loader = bool
  }),

  setModal: action((state, payload) => {
    state.modal = payload
  }),

  openModal: thunk(({ setModal }, payload) => {
    const { setLocation, ...rest } = payload
    setModal(rest)
    if (setLocation) {
      const url = new URL(location.href)
      url.searchParams.set(urlParamName, rest.name)
      setLocation(url.href)
    }
  }),

  closeModal: thunk(({ setModal }, { setLocation }) => {
    setModal(null)
    if (setLocation) {
      const url = new URL(location.href)
      url.searchParams.delete(urlParamName)
      setLocation(url.href)
    }
  }),

  openModalFromUrl: thunk(({ setModal }) => {
    const url = new URL(location.href)
    const urlModal = url.searchParams.get(urlParamName)
    console.log(urlModal, location.href)
    if (urlModal === MODALS.REFERRAL) {
      setModal({ name: MODALS.REFERRAL })
    }
  }),
}

export default uiStore
