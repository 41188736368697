import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import cl from 'classnames'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions } from 'easy-peasy'

import Loader from 'artkive/components/Loader/RingsLoader'
import { BOX_CHECKOUT } from 'artkive/constants/tracker/mainNavigation'
import usePromo from 'artkive/hooks/usePromo'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import theme from 'artkive/utils/theme'
import validations from 'artkive/utils/validations'

import useTracker from '../Tracker/useTracker'

const useStyles = makeStyles(() => ({
  applyButton: {
    borderRadius: '0 8px 8px 0',
  },
  loader: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: '2',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputWrap: {
    display: 'none',
    '&$active': {
      display: 'block',
    },
  },
  active: {},
}))

const Promo = ({ disabled, product }) => {
  const { promoActions, promoState } = usePromo({ formType: 'checkout', productName: product.type })
  const { validate } = useStoreActions(({ promoStore }) => promoStore)
  const classes = useStyles()
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ code: promoState.promo.code })

  const [trackAction] = useTracker()
  // redux state
  const orderState = useVariableState(product.type, product.uuid)

  // state
  const [error, setError] = useState()


  const onSubmit = ({ code }) => {
    trackAction(BOX_CHECKOUT.PROMO_CODE_APPLY, { code })
    promoActions.setPromo({ code, product_id: product.id, uuid: product.uuid, productName: product.type })
  }

  useEffect(() => {
    let value = promoState.promo.code

    if (typeof promoState.promo.code !== 'string') value = ''

    setValue('code', value)
  }, [promoState.promo.code])

  useEffect(() => {
    const { code, requesting, validated } = promoState.promo

    if (code === '' || requesting) return

    setError(validated ? null : 'Invalid code. Please try again')
  }, [
    orderState.price,
    promoState.promo.code,
    promoState.promo.requesting,
    promoState.promo.validated,
  ])

  useEffect(() => {
    validate({ product_id: product.id, productName: product.type, uuid: product.uuid })
  }, [])

  return (
    <Box>
      <Typography variant={'h6'} className={classes.btnWrap}>
        Promo or Gift Card Code
      </Typography>

      <form className={cl(classes.inputWrap, classes.active)} onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} position={'relative'}>
          {promoState.promo.requesting && (
            <Box className={classes.loader}>
              <Loader color={theme.colors.primary.main} />
            </Box>
          )}

          <Controller
            name={'code'}
            control={control}
            defaultValue={''}
            rules={validations.promo}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.code || !!error}
                helperText={errors?.code?.message || error || null}
                fullWidth
                name={'code'}
                onChange={onChange}
                type={'text'}
                value={value}
                variant={'outlined'}
                margin={'dense'}
                disabled={disabled}
                className={'input'}
                InputProps={{
                  style: { paddingRight: '0' },
                  endAdornment: (
                    <Button
                      color={'primary'}
                      className={classes.applyButton}
                      type={'submit'}
                      variant={'contained'}
                      disabled={disabled}
                    >
                      Apply
                    </Button>
                  ),
                }}
              />
            )}
          />
        </Box>
      </form>
    </Box>
  )
}

Promo.propTypes = {
  product: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

Promo.defaultProps = {
  disabled: false,
}

export default Promo
