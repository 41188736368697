import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import globalTheme from 'artkive/utils/theme'

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    padding: spacing(1.5, 2),
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: '0 solid white',
    marginBottom: 8,

    [breakpoints.up('md')]: {
      padding: spacing(2, 3),
    },

    '&.highlightable': {
      backgroundColor: 'white',
      borderColor: ({ active }) => active ? globalTheme.colors.sky[500] : globalTheme.colors.sky[300],
      borderWidth: '1px',
    },

    '&.dense': {
      padding: spacing(0.75, 0),

      [breakpoints.up('md')]: {
        padding: spacing(0, 1),
      },
    },
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiTypography-root.MuiTypography-body2': {
      color: palette.secondary.dark,
      fontWeight: ({ active }) => active ? 600 : 500,
    },
  },
  textRoot: {
    marginTop: 0,
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    maxWidth: ({ priceDescription }) => priceDescription ? 90 : 'unset',
    minWidth: ({ priceDescription }) => priceDescription ? 90 : 'unset',
    marginLeft: spacing(2),

    [breakpoints.up('md')]: {
      maxWidth: ({ priceDescription }) => priceDescription ? 120 : 'unset',
      minWidth: ({ priceDescription }) => priceDescription ? 120 : 'unset',
      marginLeft: 0,
    },
  },
  price: {
    minWidth: '40px',
    flex: 0,
    lineHeight: 1.5,
    color: ({ active }) => active ? palette.secondary.dark : palette.secondary.main,
    fontWeight: ({ active }) => active ? 600 : 500,
  },
  icon: {
    minWidth: 46,
  },
}))

const OptionListItem = ({
  label,
  secondaryLabel,
  priceDescription,
  price,
  control,
  active,
  component,
  className,
}) => {
  const classes = useStyles({ active, priceDescription })

  return (
    <ListItem className={cl(classes.root, className)} component={component}>
      {control && (
        <ListItemIcon className={classes.icon}>
          {control}
        </ListItemIcon>
      )}
      <ListItemText
        primary={label}
        classes={{ root: classes.text }}
        secondary={secondaryLabel}
        disableTypography
      />
      <ListItemText
        classes={{ root: classes.textRoot }}
        primary={(
          <Box textAlign={'right'}>
            <Typography variant={'body1'} className={classes.price}>
              {price}
            </Typography>
          </Box>
        )}
        secondary={priceDescription}
        disableTypography
      />
    </ListItem>
  )
}

OptionListItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondaryLabel: PropTypes.node,
  price: PropTypes.string,
  component: PropTypes.string,
  className: PropTypes.string,
  control: PropTypes.node,
  active: PropTypes.bool,
  priceDescription: PropTypes.node,
}

OptionListItem.defaultProps = {
  component: 'li',
  priceDescription: null,
}

export default OptionListItem
