import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '@material-ui/core'

import { AMOUNT_OF_ART } from 'artkive/utils/giftCard'

import HelpTooltip from '../Tooltip/HelpTooltip'

import BulletPointsList from './BulletPointsList'

const cardDetails = {
  PhysicalGiftCardOrder: [
    `Includes ${AMOUNT_OF_ART}-piece Artkive Box Package with one hard cover book`,
    <>
      A beautifully packaged Gift Card + a personal note
      <HelpTooltip>
        If you order multiple gift cards, they will all be mailed to the same address. One copy of the personal note
        will be included. Gift cards cannot be used with other promos, and multiple gift cards cannot be combined in
        one order.
      </HelpTooltip>
    </>,
    'Arrives within 5 business days',
  ],
  EGiftCardOrder: [
    `Includes a ${AMOUNT_OF_ART}-piece Artkive Box package with one hardcover book.`,
    'A festive email delivered on the date of your choosing. Easy!',
    <>
      For custom amounts, please request that <Link target={'_blank'} href={'https://docs.google.com/forms/d/e/1FAIpQLScikqIQvvRKFuw5yqMwz_sSjziGBePZ-GYA8PTrG4zekpYT6A/viewform'}>here</Link>.
      <HelpTooltip>
        Gift cards cannot be used with other promos, and multiple gift cards cannot be combined in one order.
      </HelpTooltip>
    </>,
  ],
}

const GiftCardBulletPoints = ({ type }) => {
  const bullets = cardDetails[type]
  return (
    <BulletPointsList bullets={bullets} />
  )
}

GiftCardBulletPoints.propTypes = {
  type: PropTypes.string,
}

export default GiftCardBulletPoints
